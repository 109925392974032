import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formatter } from "@omnigenbiodata/react";
import { useStyles } from "./component.style";
import {
  Aliquot,
  SampleStatus,
} from "../../../../../../../../core/api/lab.types";

export interface SamplesRowProps {
  barcode: string;
  volume: number;
  status: SampleStatus;
  aliquots: Aliquot[];
}

function SamplesRow({ barcode, volume, status, aliquots }: SamplesRowProps) {
  const classes = useStyles();

  return (
    <TableRow key={barcode}>
      <TableCell component="th" scope="row" align="left">
        <strong>{barcode}</strong>
      </TableCell>
      <TableCell align="center">{status}</TableCell>
      <TableCell align="center">
        <Formatter input={volume} />
      </TableCell>
      <TableCell align="center" className={classes.borderLeft}>
        <strong>{aliquots[0]?.barcode} </strong>
      </TableCell>
      <TableCell align="center">{aliquots[0]?.status}</TableCell>
      <TableCell align="center">{aliquots[0]?.volume}</TableCell>
      <TableCell align="center" className={classes.borderLeft}>
        <strong>{aliquots[1]?.barcode}</strong>
      </TableCell>
      <TableCell align="center">{aliquots[1]?.status}</TableCell>
      <TableCell align="center">{aliquots[1]?.volume}</TableCell>
    </TableRow>
  );
}

export default SamplesRow;
