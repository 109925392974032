import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from "../../core/api/lab.api";
import { FindCryoboxesResponse } from "../../core/api/lab.types";
import { CryoboxListFormValues } from "./types";

/* Find Sample Batches */
export const findCryoboxesThunk = createAsyncThunk(
  "cryoboxList/findCryoboxes",
  async (values: CryoboxListFormValues, { rejectWithValue }: any) => {
    const response: FindCryoboxesResponse = await labApi.findCryoboxes(
      values.searchTerm || "",
      values.freezerId || "",
      values.bioBanked || ""
    );
    if (response.data?.findCryoboxes) {
      return response.data?.findCryoboxes;
    }
    return rejectWithValue(response.errors);
  }
);
