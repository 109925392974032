import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { BsCheck } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { ButtonRow, FormStepper, theme } from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../../../layout/Inner";
import Panel from "../../../../../../../../components/layout/Panel";
import { useAppDispatch } from "../../../../../../../../store";
import {
  cryoboxCreateForward,
  cryoboxCreateReset,
} from "../../../../../../../../store/cryoboxCreate";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import FORMS, {
  MESSAGES,
} from "../../../../../../../../core/constants/forms.constants";
import {
  SampleTypeNameEnum,
  SampleTypeCodeEnum,
} from "../../../../../../../../core/api/lab.types";

function SampleTypeScene() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(cryoboxCreateReset());
  }, [dispatch]);

  const validationSchema = yup.object({
    sampleType: yup.string().required(MESSAGES.serialNoRequired),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sampleType: "",
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(cryoboxCreateForward(values));
      history.push(ROUTES.cryoboxCreateBox);
    },
  });

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4" component="h1" align="center" paragraph>
            Sample Storage
          </Typography>
          <FormStepper steps={FORMS.STORAGE_STEPS} activeStep={0} />
        </Box>
        <Panel
          mb={6}
          title="Sample Type"
          intro="Please select the sample types you are storing"
        >
          <Grid container spacing={2}>
            {Object.values(SampleTypeCodeEnum).map((sampleKey, index) => (
              <Grid item xs={12} lg={4} key={sampleKey}>
                <Button
                  variant={
                    formik.values.sampleType === sampleKey
                      ? "contained"
                      : "outlined"
                  }
                  color="default"
                  size="small"
                  fullWidth
                  onClick={() => {
                    formik.setFieldValue("sampleType", sampleKey);
                  }}
                  endIcon={
                    formik.values.sampleType === sampleKey ? (
                      <BsCheck size={30} color={theme.palette.primary.main} />
                    ) : undefined
                  }
                  centerRipple
                >
                  {SampleTypeNameEnum[sampleKey]}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Panel>
        <ButtonRow
          showBack={false}
          buttonSize="small"
          showForward={formik.isValid}
        />
      </form>
    </InnerLayout>
  );
}

export default SampleTypeScene;
