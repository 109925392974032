import { useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Markdown from "markdown-to-jsx";
import React, { ReactNode } from "react";

interface PanelProps {
  children: ReactNode;
  isContained?: boolean;
  title?: string;
  intro?: string;
  [x: string]: any;
}

function Panel({
  children,
  isContained = true,
  title,
  intro,
  ...others
}: PanelProps) {
  const isTablet = useMediaQuery("(max-width:768px)");
  return (
    <Box {...others}>
      <Paper
        variant={isContained && !isTablet ? "outlined" : undefined}
        elevation={0}
      >
        <Box p={{ xs: 1, sm: 1, md: 4 }}>
          {title && (
            <Box mb={6}>
              {title && (
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                >
                  {title}
                </Typography>
              )}
              {intro && (
                <Typography variant="body1" component="div" align="center">
                  <Markdown>{intro}</Markdown>
                </Typography>
              )}
            </Box>
          )}
          {children}
        </Box>
      </Paper>
    </Box>
  );
}

export default Panel;
