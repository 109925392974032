import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $flag2": {
      borderTopWidth: 130,
      borderRightWidth: 130,
    },
    "&:hover $flagShadow": {
      borderTopWidth: 140,
      borderRightWidth: 140,
    },
    "&:hover $flagVersion": {
      top: 7,
      left: 7,
      zIndex: 101,
    },
  },
  flagText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotateY(0deg) rotate(-45deg)",
    position: "absolute",
    top: -17,
    left: -17,
    width: 100,
    height: 100,
    textAlign: "center",
    fontSize: 19,
    color: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
    zIndex: 99,
  },
  flagDot: {
    position: "absolute",
    top: 12,
    left: 12,
    height: 10,
    width: 10,
    backgroundColor: "white",
    borderRadius: "50%",
    display: "inline-block",
    zIndex: 99,
    padding: 1,

    "&:after": {
      content: "''",
      height: 8,
      width: 8,
      backgroundColor: "green",
      borderRadius: "50%",
      display: "block",
      animation: `$pulse 1000ms infinite`,
    },
  },
  flag: {
    display: "flex",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 100,
    borderRightWidth: 100,
    borderBottomWidth: 0,
    borderColor: "#943a38 transparent transparent transparent",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 98,
  },
  flagVersion: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotateY(0deg) rotate(-45deg)",
    position: "absolute",
    top: -7,
    left: -7,
    width: 100,
    height: 100,
    textAlign: "center",
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "bold",
    textTransform: "uppercase",
    zIndex: 97,
    transition: "all linear 100ms",
    overflow: "hidden",
  },
  flag2: {
    display: "flex",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 100,
    borderRightWidth: 100,
    borderBottomWidth: 0,
    borderColor: "#e0e0e0 transparent transparent transparent",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 96,
    transition: "all linear 100ms",
  },
  flagShadow: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 110,
    borderRightWidth: 110,
    borderBottomWidth: 0,
    borderColor: "black transparent transparent transparent",
    position: "absolute",
    opacity: 0.2,
    top: 0,
    left: 0,
    zIndex: 95,
    transition: "all linear 100ms",
  },
  flagTest: {
    borderColor: "#4585b7 transparent transparent transparent",
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));
