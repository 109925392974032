import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { RiBarcodeLine } from "react-icons/ri";

interface PlaceholderProps {
  [x: string]: any;
}

const Placeholder = ({ ...others }: PlaceholderProps) => (
  <Box {...others}>
    <div style={{ fontSize: 100, textAlign: "center" }}>
      <RiBarcodeLine color="#cccccc" />
    </div>

    <Typography align="center">
      Scan your first primary sample tube barcode
    </Typography>
  </Box>
);

export default Placeholder;
