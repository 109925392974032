import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Formatter } from "@omnigenbiodata/react";
import { Grid } from "@material-ui/core";
import { SampleTypeCodeEnum } from "../../../../../../../../core/api/lab.types";
import { Panel } from "../../../../../../../../components";

export interface BatchSummaryProps {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
}

function BatchSummary({ batchId, sampleType, createdTs }: BatchSummaryProps) {
  return (
    <Panel mb={4}>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Chip label={sampleType} color="primary" variant="outlined" />
        </Grid>
        <Grid item xs>
          <Typography component="h2" variant="h5">
            {batchId}
          </Typography>
          <Typography component="h2" variant="body1">
            <Formatter input={createdTs} format="friendlyDateTime" />
          </Typography>
        </Grid>
      </Grid>
    </Panel>
  );
}

export default BatchSummary;
