import React, { useEffect } from "react";
import onScanLib from "onscan.js";

interface ScanEventProps {
  onScan: (code: string) => void;
  [x: string]: any;
}

function ScanEvent({ onScan }: ScanEventProps) {
  useEffect(() => {
    onScanLib.attachTo(document, {
      ignoreIfFocusOn: "input" as any,
      onScan: function (sCode, iQty) {
        onScan(sCode);
      },
    });
    return () => {
      onScanLib.detachFrom(document);
    };
  }, [onScan]);
  return <div data-testid="scanner"></div>;
}

export default ScanEvent;
