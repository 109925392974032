import React from "react";
import { Route, Switch } from "react-router-dom";
import ListScene from "./scenes/List";
import CreateScene from "./scenes/Create";
import EditScene from "./scenes/Edit";
import ROUTES from "../../../../core/constants/routes.constants";

function PortalScene() {
  return (
    <Switch>
      <Route path={ROUTES.cryoboxCreate} component={CreateScene} />
      <Route exact path={ROUTES.cryobox} component={ListScene} />
      <Route exact path={ROUTES.cryoboxEdit} component={EditScene} />
    </Switch>
  );
}

export default PortalScene;
