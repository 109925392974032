import React from "react";
import { Link } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from "./component.style";
import { SampleTypeCodeEnum } from "../../../../../../../../core/api/lab.types";

export interface CryoboxRowProps {
  serialNo: string;
  sampleType: SampleTypeCodeEnum;
  freezerName: string;
  freezerShelfNo: string;
  shelfRackNo: string;
  rackPositionNo: string;
  editLink: string;
  searchTerm: string | null;
  aliquotPositions: string[] | null;
}

function CryoboxRow({
  serialNo,
  sampleType,
  freezerName,
  freezerShelfNo,
  shelfRackNo,
  rackPositionNo,
  editLink,
  searchTerm,
  aliquotPositions,
}: CryoboxRowProps) {
  const classes = useStyles();
  return (
    <TableRow key={serialNo}>
      <TableCell align="left">
        <Link color="default" to={editLink} className={classes.compact}>
          <strong>{serialNo}</strong>
        </Link>
      </TableCell>
      <TableCell align="center">{sampleType}</TableCell>
      <TableCell align="center">{freezerName}</TableCell>
      <TableCell align="center">{freezerShelfNo}</TableCell>
      <TableCell align="center">{shelfRackNo}</TableCell>
      <TableCell align="center">{rackPositionNo}</TableCell>
      <TableCell align="center">
        {searchTerm &&
        aliquotPositions &&
        aliquotPositions?.lastIndexOf(searchTerm) >= 0
          ? aliquotPositions?.lastIndexOf(searchTerm) + 1
          : null}
      </TableCell>
    </TableRow>
  );
}

export default CryoboxRow;
