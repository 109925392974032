import React, { ReactChild, ReactFragment, ReactPortal } from "react";
import { Link } from "react-router-dom";

import Fade from "@material-ui/core/Fade";
import { PageWrapper, DiscoverMeLogo } from "@omnigenbiodata/react";

import { useStyles } from "./component.styles";
import ANIMATIONS from "../../core/constants/animations.constants";

interface InnerLayoutProps {
  children: boolean | ReactChild | ReactFragment | ReactPortal;
}

function InnerLayout({ children }: InnerLayoutProps) {
  const classes = useStyles();

  return (
    <>
      <PageWrapper>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Link to="/" className={classes.logo}>
              <DiscoverMeLogo sub="sa" size="small" />
            </Link>
          </div>
        </div>
        <Fade in timeout={ANIMATIONS.duration}>
          <div className={classes.content}>{children}</div>
        </Fade>
      </PageWrapper>
    </>
  );
}

export default InnerLayout;
