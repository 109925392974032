import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React from "react";
import { RiRefreshLine } from "react-icons/ri";
import _ from "lodash";
import ScannedCell from "../ScannedCell";
import { useStyles } from "./component.styles";

interface CryoboxGridProps {
  allowRemoveLast?: boolean;
  errors?: string | string[];
  height?: number;
  onClearAll?: () => void;
  onRemove?: (index: number) => void;
  onSelect?: (index: number) => void;
  values: string[];
  width?: number;
}

function CryoboxGrid({
  allowRemoveLast = true,
  errors,
  height = 0,
  onClearAll,
  onRemove,
  onSelect,
  values,
  width = 0,
}: CryoboxGridProps) {
  const capacity = width * height;
  const chunks = _.times(capacity, _.constant(""));
  const rows = _.chunk(chunks, width);
  const styles = useStyles();

  return (
    <div>
      {onClearAll && values.length > 0 && (
        <Box mb={2} textAlign="right">
          <Button
            aria-label="Valid Aliquot"
            size="small"
            startIcon={<RiRefreshLine title="Undo" fontSize={30} />}
            onClick={() => {
              onClearAll();
            }}
          >
            Clear all
          </Button>
        </Box>
      )}
      {rows.map((row, rowIndex) => (
        <div className={styles.aliquotRow} key={`col-${rowIndex}`}>
          {row.map((col, colIndex) => {
            const cellIndex = Math.ceil(colIndex) + Math.ceil(rowIndex * width);
            const cellValue = values ? values[cellIndex] : undefined;
            return (
              <ScannedCell
                key={cellIndex}
                value={cellValue}
                position={cellIndex + 1}
                totalPositions={values ? values.length : 0}
                onRemove={onRemove}
                onSelect={onSelect}
                allowRemoval={allowRemoveLast}
                hasError={
                  errors && errors[cellIndex] ? errors[cellIndex] : false
                }
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default CryoboxGrid;
