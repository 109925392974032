import React, { useEffect } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  ButtonRow,
  FormInput,
  FormSelect,
  FormStepper,
  Loader,
  SelectOption,
} from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../../../layout/Inner";
import Panel from "../../../../../../../../components/layout/Panel";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store";
import { createCryoboxThunk } from "../../../../../../../../store/cryoboxCreate";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import FORMS, {
  MESSAGES,
} from "../../../../../../../../core/constants/forms.constants";
import {
  valuesSelector,
  responseSelector,
  hasErrorSelector,
  isBusySelector,
} from "../../../../../../../../store/cryoboxCreate/selectors";
import {
  listFreezersThunk,
  responseSelector as freezerResponseSelector,
} from "../../../../../../../../store/freezerList";
import CryoboxSummary from "../../../../components/CryoboxSummary";
import { Freezer } from "../../../../../../../../core/api/lab.types";
import { AlertError } from "../../../../../../../../components";

function FreezerScene() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(valuesSelector);
  const response = useAppSelector(responseSelector);
  const hasError = useAppSelector(hasErrorSelector);
  const isBusy = useAppSelector(isBusySelector);
  const freezerList = useAppSelector(freezerResponseSelector);

  useEffect(() => {
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [freezerList, dispatch]);

  const validationSchema = yup.object({
    freezerId: yup.string().required(MESSAGES.freezerIdRequired),
    freezerShelfNo: yup.string().required(MESSAGES.freezerShelfNoRequired),
    shelfRackNo: yup.string().required(MESSAGES.shelfRackNoRequired),
    rackPositionNo: yup.string().required(MESSAGES.rackPositionNoRequired),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      freezerId: "",
      freezerShelfNo: "",
      shelfRackNo: "",
      rackPositionNo: "",
      ...values,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(createCryoboxThunk(values as any));
    },
  });

  if (response) {
    return <Redirect to={ROUTES.cryoboxCreateComplete} />;
  }

  if (!values) {
    return <Redirect to={ROUTES.cryoboxCreate} />;
  }

  return (
    <>
      <InnerLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={2}>
            <Typography variant="h4" component="h1" align="center" paragraph>
              Sample Storage
            </Typography>
            <FormStepper steps={FORMS.STORAGE_STEPS} activeStep={3} />
          </Box>
          <CryoboxSummary
            serialNo={formik.values.serialNo}
            sampleType={formik.values.sampleType}
          />
          <Panel
            mb={6}
            title="Storage Location"
            intro="Please assign a storage location to the cryobox"
          >
            {hasError && !isBusy && (
              <AlertError
                title="Submission error"
                description="There was an unexpected problem submitting your storage
              details."
              />
            )}

            <FormSelect
              label="Freezer"
              name={`freezerId`}
              error={formik.errors.freezerId}
              touched={formik.touched.freezerId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Freezer"
              options={
                freezerList
                  ? (freezerList.map((freezer: Freezer) => ({
                      value: freezer.freezerId,
                      label: `${freezer.name} (${freezer.serialNo})`,
                    })) as SelectOption[])
                  : []
              }
              value={formik.values.freezerId}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormInput
                  label="Freezer Shelf No."
                  type="text"
                  name={`freezerShelfNo`}
                  error={formik.errors.freezerShelfNo}
                  touched={formik.touched.freezerShelfNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.freezerShelfNo}
                  startAdornment={<AiOutlineNumber />}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  label="Shelf Rack No."
                  type="text"
                  name={`shelfRackNo`}
                  error={formik.errors.shelfRackNo}
                  touched={formik.touched.shelfRackNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shelfRackNo}
                  startAdornment={<AiOutlineNumber />}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  label="Rack Position No."
                  type="text"
                  name={`rackPositionNo`}
                  error={formik.errors.rackPositionNo}
                  touched={formik.touched.rackPositionNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rackPositionNo}
                  startAdornment={<AiOutlineNumber />}
                />
              </Grid>
            </Grid>
          </Panel>
          <ButtonRow buttonSize="small" forwardLabel="Save Cryobox" />
        </form>
      </InnerLayout>
      <Loader isVisible={isBusy} label={"Submitting"} />
    </>
  );
}

export default FreezerScene;
