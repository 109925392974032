import React from "react";
import { Route, Switch } from "react-router-dom";
import CompleteScene from "./scenes/Complete";
import CryoBoxScene from "./scenes/CryoBox";
import FreezerScene from "./scenes/Freezer";
import SampleTypeScene from "./scenes/SampleType";
import AliquotsScene from "./scenes/Aliquots";
import ROUTES from "../../../../../../core/constants/routes.constants";

function CreateScene() {
  return (
    <Switch>
      <Route path={ROUTES.cryoboxCreateComplete} component={CompleteScene} />
      <Route path={ROUTES.cryoboxCreateFreezer} component={FreezerScene} />
      <Route path={ROUTES.cryoboxCreateAliquots} component={AliquotsScene} />
      <Route path={ROUTES.cryoboxCreateBox} component={CryoBoxScene} />
      <Route path={ROUTES.cryoboxCreate} component={SampleTypeScene} />
    </Switch>
  );
}

export default CreateScene;
