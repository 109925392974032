import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ButtonRow, FormStepper } from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../../../layout/Inner";
import Panel from "../../../../../../../../components/layout/Panel";
import FORMS from "../../../../../../../../core/constants/forms.constants";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import AlertSuccess from "../../../../../../../../components/content/AlertSuccess";

const CompleteScene = () => {
  const history = useHistory();
  return (
    <InnerLayout>
      <Box mb={2}>
        <Typography variant="h4" component="h1" align="center" paragraph>
          Sample Processing
        </Typography>
        <FormStepper
          steps={FORMS.PROCESSING_STEPS}
          activeStep={FORMS.PROCESSING_STEPS.length}
        />
      </Box>
      <Panel mb={6} title="Samples &amp; Aliquots Submitted">
        <AlertSuccess
          title="Success"
          description="We have received your samples and aliquots. You can now
          end sample processing."
        />
      </Panel>
      <ButtonRow
        showBack={false}
        forwardLabel="End processing"
        onForward={() => {
          history.push(ROUTES.portal);
        }}
        buttonSize="small"
      />
    </InnerLayout>
  );
};

export default CompleteScene;
