import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { RiLockPasswordLine } from "react-icons/ri";
import { ButtonRow, FormInput, Loader, useAuth } from "@omnigenbiodata/react";
import MainLayout from "../../../../layout/Main";

import ROUTES from "../../../../core/constants/routes.constants";
import {
  MESSAGES,
  PASSWORD_REGEX,
} from "../../../../core/constants/forms.constants";

const validationSchema = yup.object().shape({
  code: yup.string().required(MESSAGES.emailRequired),
  password: yup
    .string()
    .required(MESSAGES.passwordRequired)
    .matches(PASSWORD_REGEX, MESSAGES.passwordFormat),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], MESSAGES.passwordNoMatch)
    .required(MESSAGES.passwordConfirmRequired),
});

function SubmitResetPassword() {
  const history = useHistory();

  const {
    forgotPasswordHasError,
    isBusy,
    forgotPasswordEmail,
    forgotPasswordSubmitHasSuccess,
    forgotPasswordSubmit,
    forgotPasswordDeliveryDetails,
    reset,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: forgotPasswordEmail,
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values: any) => {
      forgotPasswordSubmit(values);
    },
  });

  const handleCancel = () => {
    reset(); //TODO add to context
    history.push(ROUTES.auth);
  };

  return (
    <>
      {!forgotPasswordEmail && <Redirect to={ROUTES.authResetPassword} />}
      {forgotPasswordSubmitHasSuccess && <Redirect to={ROUTES.auth} />}
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Password Recovery
            </Typography>

            {forgotPasswordDeliveryDetails &&
              forgotPasswordDeliveryDetails.DeliveryMedium === "EMAIL" && (
                <Typography paragraph variant="body1" align="center">
                  A 6-digit recovery code has been sent to your email address (
                  {forgotPasswordDeliveryDetails.Destination}).
                </Typography>
              )}

            {forgotPasswordDeliveryDetails &&
              forgotPasswordDeliveryDetails.DeliveryMedium === "SMS" && (
                <Typography paragraph variant="body1" align="center">
                  A 6-digit recovery code has been sent to your mobile number (
                  {forgotPasswordDeliveryDetails.Destination}).
                </Typography>
              )}

            <Typography paragraph variant="body1" align="center">
              Please enter the code and a new password below:
            </Typography>
          </Box>
          {forgotPasswordHasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Incorrect Code</strong>
                </Typography>
                <Typography variant="body1">
                  Please check that you have entered the 6-digit recovery code
                  correctly.
                </Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.code}
              name="code"
              label="Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.code}
              value={formik.values.code}
            />
            <FormInput
              error={formik.errors.password}
              name="password"
              label="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
            <FormInput
              error={formik.errors.confirmPassword}
              name="confirmPassword"
              label="Confirm New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.confirmPassword}
              value={formik.values.confirmPassword}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
          </Box>
          <ButtonRow
            forwardLabel="Confirm"
            backLabel="Cancel"
            onBack={handleCancel}
            buttonSize="small"
          />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default SubmitResetPassword;
