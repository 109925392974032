export const AUTH_CHALLENGES = {
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  MFA_SETUP: "MFA_SETUP",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  NEW_PASSWORD: "NEW_PASSWORD",
  CUSTOM_CHALLENGE: "CUSTOM_CHALLENGE",
};

export const MFA_ISSUER =
  process.env.REACT_APP_ENV === "production"
    ? `DMSA_LAB_STAFF`
    : `DMSA_LAB_STAFF_${process.env.REACT_APP_ENV?.toUpperCase()}`;

export const COGNITO_CONFIG = {
  region: process.env.REACT_APP_COGNITO_REGION,
  user_pool_id: process.env.REACT_APP_USERPOOL_ID,
  identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  app_client_id: process.env.REACT_APP_CLIENT_ID,
  auth_flow: process.env.REACT_APP_COGNITO_AUTH_FLOW,
};

export const APPSYNC_CONFIG = {
  region: process.env.REACT_APP_APPSYNC_REGION,
  url: process.env.REACT_APP_APPSYNC_URL,
  authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
};

export const AWS_CONFIG = {
  region: COGNITO_CONFIG.region,
  aws_cognito_region: COGNITO_CONFIG.region,
  userPoolId: COGNITO_CONFIG.user_pool_id,
  identityPoolId: COGNITO_CONFIG.identity_pool_id,
  userPoolWebClientId: COGNITO_CONFIG.app_client_id,
  authenticationFlowType: COGNITO_CONFIG.auth_flow,
  aws_appsync_graphqlEndpoint: APPSYNC_CONFIG.url,
  aws_appsync_region: APPSYNC_CONFIG.region,
  aws_appsync_authenticationType: APPSYNC_CONFIG.authenticationType,
  Storage: {
    AWSS3: {
      region: COGNITO_CONFIG.region,
    },
  },
};

export default AWS_CONFIG;
