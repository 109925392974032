import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  leftCellRed: {
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.error.dark,
  },
  leftCellPurple: {
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    borderLeftColor: "purple",
  },
}));
