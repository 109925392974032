import React from "react";
import { Route, Switch } from "react-router-dom";
import ROUTES from "../../core/constants/routes.constants";
import HomeScene from "./scenes/Home";
import ProcessingScene from "./scenes/Processing";
import StorageScene from "./scenes/Storage";

function PortalScene() {
  return (
    <Switch>
      <Route path={ROUTES.cryobox} component={StorageScene} />
      <Route path={ROUTES.batches} component={ProcessingScene} />
      <Route path={ROUTES.portal} component={HomeScene} />
    </Switch>
  );
}

export default PortalScene;
