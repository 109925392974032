import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: theme.spacing(4),
    },
  },
  headerActions: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: { marginLeft: "auto" },
  },
  headerContent: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: { marginRight: "auto", marginBottom: 0 },
  },
  logo: {
    maxWidth: 500,
    height: 100,
  },
  content: {
    flexGrow: 1,
  },
  footer: { flexGrow: 0 },
}));
