import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";
import { SampleBatchLookup } from "../../core/api/lab.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.batchList.errors && state.batchList.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.batchList.status === StoreStatus.BUSY;

export const searchTermSelector = (state: RootState): string | null =>
  state.batchList.values?.searchTerm || null;

export const responseSelector = (
  state: RootState
): SampleBatchLookup[] | null => state.batchList.response || null;
