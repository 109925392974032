import React from "react";
import { Route, Switch } from "react-router-dom";
import ListScene from "./scenes/List";
import EditScene from "./scenes/Edit";
import CreateScene from "./scenes/Create";

import ROUTES from "../../../../core/constants/routes.constants";

function PortalScene() {
  return (
    <Switch>
      <Route exact path={ROUTES.batches} component={ListScene} />
      <Route path={ROUTES.batchesCreate} component={CreateScene} />
      <Route exact path={ROUTES.batchesEdit} component={EditScene} />
    </Switch>
  );
}

export default PortalScene;
