import React, { ReactNode } from "react";
import Box from "@material-ui/core/Box";

interface AliquotConfirmListProps {
  children: ReactNode;
}

const AliquotConfirmList = ({ children }: AliquotConfirmListProps) => {
  return (
    <Box>
      <table width={"100%"} style={{ margin: "0 auto" }}>
        <thead>
          <tr>
            <th align="center" style={{ width: 40 }}></th>
            <th align="center" style={{ minWidth: 100, maxWidth: 150 }}>
              Barcode ID
            </th>
            <th align="left">Aliquot 1 ID</th>
            <th align="left">Aliquot 2 ID</th>
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </Box>
  );
};

export default AliquotConfirmList;
