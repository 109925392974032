import React, { ReactNode } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from "./component.style";
import Box from "@material-ui/core/Box";

export interface SamplesTableProps {
  children: ReactNode;
}

function SamplesTable({ children }: SamplesTableProps) {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Primary
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                className={classes.borderLeft}
              >
                Aliquot 1
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                className={classes.borderLeft}
              >
                Aliquot 2
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
              <TableCell align="center" className={classes.borderLeft}>
                ID
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
              <TableCell align="center" className={classes.borderLeft}>
                ID
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Vol (ml)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SamplesTable;
