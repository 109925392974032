import { createSlice } from "@reduxjs/toolkit";
import { BatchCreateState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { createSampleBatchThunk } from "./thunks";

// Initial State
const initialState: BatchCreateState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
};

// Slice
export const batchCreateSlice = createSlice({
  name: "batchCreate",
  initialState,
  reducers: {
    batchCreateForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
      errors: null,
      response: null,
    }),
  },
  extraReducers: {
    [createSampleBatchThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [createSampleBatchThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
      state.values = null;
    },
    [createSampleBatchThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
  },
});

// Action creators
export const { batchCreateForward } = batchCreateSlice.actions;
export * from "./thunks";
export default batchCreateSlice.reducer;
