import { createSlice } from "@reduxjs/toolkit";
import { CryoboxCreateState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { createCryoboxThunk } from "./thunks";

// Initial State
const initialState: CryoboxCreateState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
};

// Slice
export const cryoboxCreateSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    cryoboxCreateForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
      errors: null,
      response: null,
    }),
    cryoboxCreateReset: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [createCryoboxThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [createCryoboxThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
      state.values = null;
    },
    [createCryoboxThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
  },
});

// Action creators
export const { cryoboxCreateForward, cryoboxCreateReset } =
  cryoboxCreateSlice.actions;
export * from "./thunks";
export default cryoboxCreateSlice.reducer;
