import React from "react";
import { Link } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Formatter } from "@omnigenbiodata/react";
import { SampleTypeCodeEnum } from "../../../../../../../../core/api/lab.types";

export interface BatchRowProps {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
  editLink: string;
}

function BatchRow({ batchId, sampleType, createdTs, editLink }: BatchRowProps) {
  return (
    <TableRow key={batchId}>
      <TableCell align="left">
        <Link color="default" to={editLink}>
          <strong>{batchId}</strong>{" "}
        </Link>
      </TableCell>
      <TableCell align="center">{sampleType}</TableCell>
      <TableCell align="center">
        <Formatter input={createdTs} format="friendlyDateTime" />
      </TableCell>
    </TableRow>
  );
}

export default BatchRow;
