import React from "react";
import Alert from "@material-ui/lab/Alert";

function AlertNoResults() {
  return (
    <Alert severity="info">
      <strong>No matches</strong> <br />
      There were no cryoboxes matching your search criteria. Please check that
      you have entered a search term or selected a freezer.
    </Alert>
  );
}

export default AlertNoResults;
