import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ButtonRow, FormStepper } from "@omnigenbiodata/react";
import { Redirect, useHistory } from "react-router-dom";
import InnerLayout from "../../../../../../../../layout/Inner";
import FORMS from "../../../../../../../../core/constants/forms.constants";
import { Panel } from "../../../../../../../../components";
import { useAppSelector } from "../../../../../../../../store";
import {
  samplesSelector,
  sampleTypeSelector,
} from "../../../../../../../../store/batchCreate/selectors";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { StartAliquottingList, StartAliquottingRow } from "./components";
import Alert from "@material-ui/lab/Alert";
import { SampleFormValues } from "../../../../../../../../store/batchCreate/types";
import { SampleTypeNameEnum } from "../../../../../../../../core/api/lab.types";
import { getAliquotsSchema } from "../../../../../../../../core/validation/aliquots.validation";

function StartAliquottingScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      samples: samples || [],
    },
    validationSchema: getAliquotsSchema(sampleType),
    onSubmit: (values) => {
      history.push(ROUTES.batchesCreateAliquots2);
    },
  });

  if (!sampleType || !samples) {
    return <Redirect to={ROUTES.batchesCreate} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {() => (
              <>
                <Typography
                  variant="h4"
                  component="h1"
                  align="center"
                  paragraph
                >
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={3} />
                <Panel
                  mb={6}
                  title="Start Aliquotting"
                  intro={`You may now proceed with aliquotting the **${SampleTypeNameEnum[sampleType]}** samples.`}
                >
                  <Box mb={2}>
                    <Alert severity="info">{`YOU MUST KEEP THE ALIQUOTS IN THE ORDER SHOWN BELOW`}</Alert>
                  </Box>

                  {formik.values.samples.length > 0 && (
                    <StartAliquottingList>
                      {formik.values.samples.map(
                        (sample: SampleFormValues, index: number) => (
                          <StartAliquottingRow
                            index={index}
                            key={`${sample.barcode}-${index}`}
                            sampleType={sampleType}
                          />
                        )
                      )}
                    </StartAliquottingList>
                  )}
                </Panel>
                <ButtonRow
                  showForward={formik.isValid}
                  forwardLabel="Aliquotting Complete"
                  buttonSize="small"
                />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default StartAliquottingScene;
