import React, { useEffect, useMemo, useRef } from "react";
// import Typography from "@material-ui/core/Typography";
import { useFormikContext } from "formik";
import { FormInput, theme } from "@omnigenbiodata/react";
import { RiBarcodeLine, RiAlertLine } from "react-icons/ri";
import { useStyles } from "./component.styles";
import clsx from "classnames";
import { SampleFormValues } from "../../../../../../../../../../store/batchCreate/types";
import Typography from "@material-ui/core/Typography";
import EndAdornment from "./components/EndAdornment";
import { SampleTypeCodeEnum } from "../../../../../../../../../../core/api/lab.types";

interface CryoVialRowProps {
  index: number;
  sampleType: SampleTypeCodeEnum;
  onDelete: (index: number, colIndex: number) => void;
  onLock: (index: number, colIndex: number) => void;
}

const CryoVialRow = ({
  index,
  sampleType,
  onDelete,
  onLock,
}: CryoVialRowProps) => {
  const formik = useFormikContext<{
    samples: SampleFormValues[];
  }>();
  const classes = useStyles();
  const myRef = useRef<null | HTMLTableRowElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [myRef, formik.values.samples]);

  const rowActive = useMemo(() => {
    return (
      formik.values.samples
        .filter((sample) => {
          return (
            (!sample.aliquot1IDConfirm && !sample.aliquot2IDConfirm) ||
            (sample.aliquot1IDConfirm && !sample.aliquot2IDConfirm)
          );
        }, [])
        .map((sample) => sample.barcode)
        .shift() === (formik.values?.samples[index] as SampleFormValues).barcode
    );
  }, [formik.values, index]);

  const { barcode, aliquot1IDConfirm, aliquot2IDConfirm } =
    formik.values.samples[index];
  const rowErrors: any = formik.errors.samples
    ? formik.errors.samples[index]
    : {};
  const rowTouched: any = formik.touched.samples
    ? formik.touched.samples[index]
    : {};
  const rowIsLocked = aliquot2IDConfirm && aliquot1IDConfirm;
  const prevRowErrors: any =
    formik.errors.samples && formik.errors.samples[index - 1]
      ? formik.errors.samples[index - 1]
      : {};
  const prevRowValues: any =
    formik.values.samples && formik.values.samples[index - 1]
      ? formik.values.samples[index - 1]
      : {};

  return (
    <tr
      ref={rowActive ? myRef : undefined}
      className={clsx({
        [classes.newCell]: rowActive,
        [classes.pendingCell]:
          (!rowActive && !rowIsLocked) ||
          (prevRowErrors.aliquot1IDConfirm &&
            !prevRowValues.aliquot1IDMismatch) ||
          (prevRowErrors.aliquot2IDConfirm &&
            !prevRowValues.aliquot2IDMismatch),
      })}
    >
      <td
        align="center"
        className={clsx({
          [classes.leftCellRed]: sampleType === SampleTypeCodeEnum.SERUM,
          [classes.leftCellPurple]: sampleType !== SampleTypeCodeEnum.SERUM,
        })}
      >
        {index + 1}
      </td>
      <td align="center">
        <Typography variant="body1" component="p" align="center">
          <RiBarcodeLine
            fontSize={25}
            color={theme.palette.grey[500]}
            style={{ verticalAlign: "middle", marginRight: 5 }}
          />
          {barcode}
        </Typography>
      </td>
      <td>
        <FormInput
          label="Aliquot 1 ID"
          type="text"
          name={`samples.${index}.aliquot1IDConfirm`}
          error={rowErrors ? rowErrors.aliquot1IDConfirm : undefined}
          touched={aliquot1IDConfirm ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot1IDConfirm || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot1IDConfirm &&
            !aliquot1IDConfirm &&
            !rowTouched?.aliquot1IDConfirm
          }
          startAdornment={
            rowErrors?.aliquot1IDConfirm && rowTouched?.aliquot1IDConfirm ? (
              <RiAlertLine fontSize={25} color={theme.palette.error.main} />
            ) : (
              <RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />
            )
          }
          endAdornment={
            <EndAdornment
              value={aliquot1IDConfirm}
              touched={rowTouched && rowTouched.aliquot1IDConfirm}
              errors={rowErrors && rowErrors.aliquot1IDConfirm}
              isActive={
                rowActive &&
                !aliquot1IDConfirm &&
                !aliquot2IDConfirm &&
                !prevRowErrors.aliquot1IDConfirm &&
                !prevRowErrors.aliquot2IDConfirm
              }
              index={index}
              columnIndex={0}
              onDelete={onDelete}
              onLock={onLock}
            />
          }
        />
      </td>
      <td>
        <FormInput
          label="Aliquot 2 ID"
          type="text"
          name={`samples.${index}.aliquot2IDConfirm`}
          error={rowErrors ? rowErrors.aliquot2IDConfirm : undefined}
          touched={aliquot2IDConfirm ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot2IDConfirm || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot2IDConfirm &&
            !aliquot2IDConfirm &&
            !rowTouched?.aliquot2IDConfirm
          }
          startAdornment={
            rowErrors?.aliquot2IDConfirm && rowTouched?.aliquot2IDConfirm ? (
              <RiAlertLine fontSize={25} color={theme.palette.error.main} />
            ) : (
              <RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />
            )
          }
          endAdornment={
            <EndAdornment
              value={aliquot2IDConfirm}
              touched={rowTouched && rowTouched.aliquot2IDConfirm}
              errors={rowErrors && rowErrors.aliquot2IDConfirm}
              isActive={
                rowActive &&
                aliquot1IDConfirm &&
                !rowErrors.aliquot1IDConfirm &&
                !aliquot2IDConfirm &&
                !prevRowErrors.aliquot1IDConfirm &&
                !prevRowErrors.aliquot2IDConfirm
              }
              index={index}
              columnIndex={1}
              onDelete={onDelete}
              onLock={onLock}
            />
          }
        />
      </td>
    </tr>
  );
};

export default CryoVialRow;
