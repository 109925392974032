import React from "react";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import * as yup from "yup";
import { RiMailLine, RiLockPasswordLine } from "react-icons/ri";
import Typography from "@material-ui/core/Typography";
import { ButtonRow, FormInput, Loader } from "@omnigenbiodata/react";

import MainLayout from "../../../../layout/Main";
import { useAuth } from "@omnigenbiodata/react";
import { MESSAGES } from "../../../../core/constants/forms.constants";
import ROUTES from "../../../../core/constants/routes.constants";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.emailInvalid)
    .required(MESSAGES.emailRequired),
  password: yup.string().required(MESSAGES.passwordRequired),
});

function Login() {
  const history = useHistory();
  const { hasError, isBusy, forgotPasswordHasSuccess, signIn } = useAuth();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: (values) => {
      signIn(values.email, values.password);
    },
  });

  const handleCancel = () => {
    history.push(ROUTES.root);
  };

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Laboratory Portal
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please input your Discover Me lab member email and password
            </Typography>
          </Box>

          {hasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Login failed</strong>
                </Typography>
                <Typography variant="body1">
                  There was a problem with your username / password
                </Typography>
              </Alert>
            </Box>
          )}

          {forgotPasswordHasSuccess && (
            <Box mb={2}>
              <Alert severity="success">
                Your password has successfully been reset. Please login below
                using your new password.
              </Alert>
            </Box>
          )}

          <Box mb={8}>
            <FormInput
              error={formik.errors.email}
              name="email"
              label="Discover Me E-mail Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              value={formik.values.email}
              startAdornment={<RiMailLine fontSize="large" />}
            />
            <FormInput
              error={formik.errors.password}
              name="password"
              label="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine fontSize="large" />}
            />
            <Link to={`${ROUTES.authResetPassword}`}>
              <Typography
                paragraph
                variant="body1"
                align="right"
                color="textPrimary"
              >
                Forgot Password?
              </Typography>
            </Link>
          </Box>
          <ButtonRow
            forwardLabel="Sign in"
            onBack={handleCancel}
            buttonSize="small"
          />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Authenticating" />
    </>
  );
}

export default Login;
