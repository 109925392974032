/* Types */
export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export enum SampleTypeCodeEnum {
  WHOLE = "WHOLE",
  PLASMA = "PLASMA",
  SERUM = "SERUM",
}

export enum SampleTypeNameEnum {
  WHOLE = "Whole Blood",
  PLASMA = "Plasma Blood",
  SERUM = "Serum Blood",
}

export enum AliquotStatus {
  SAT,
  CLT,
  CTM,
  HEM,
  SHV,
  COC,
  OTH,
}

export enum SampleStatus {
  SAT,
  EQF,
  LBE,
  PST,
  CLT,
  CTM,
  HEM,
  ICT,
  LIP,
  LYS,
  SNP,
}

/* Payloads */
export interface FindSampleBatchPayload {
  searchTerm?: string | null;
}

export interface CreateSampleBatchInput {
  sampleType: SampleTypeCodeEnum | null;
  samples: any;
}

export type CreateCryoBoxInput = {
  serialNo: string;
  height: string;
  width: string;
  sampleType: SampleTypeCodeEnum;
  aliquotPositions: string[];
  bioBanked: string;
  freezerId: string;
  freezerShelfNo: string;
  shelfRackNo: string;
  rackPositionNo: string;
};

export type UpdateCryoBoxInput = {
  serialNo: string;
  aliquotPositions: string[];
  freezerId: string;
  freezerShelfNo: string;
  shelfRackNo: string;
  rackPositionNo: string;
  bioBanked: string;
};

/* Interfaces */

export interface Freezer {
  freezerId: string;
  name: string;
  serialNo: string;
  temp: string;
}

/* Checked */
export interface Cryobox {
  aliquotPositions: string[];
  bioBanked: string;
  createdBy: string;
  createdTs: string;
  freezerShelfNo: string;
  freezer: Freezer;
  height: number;
  lastUpdatedBy: string;
  rackPositionNo: string;
  sampleType: SampleTypeCodeEnum;
  serialNo: string;
  shelfRackNo: string;
  updatedTs: string;
  width: number;
}

/* Checked */
export interface Aliquot {
  barcode: string;
  volume: number;
  status: AliquotStatus;
  mismatch: boolean;
  sampleBatchId: string;
  sampleBarcode: string;
  createdTs: string;
  updatedTs: string;
  createdBy: string;
  lastUpdatedBy: string;
}

/* Checked */
export interface Sample {
  barcode: string;
  status: SampleStatus;
  volume: number;
  aliquots: Aliquot[];
}

/* Checked */
export interface SampleBatchLookup {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  createdTs: string;
}

/* Checked */
export interface SampleBatch {
  batchId: string;
  sampleType: SampleTypeCodeEnum;
  samples: [Sample];
  createdTs: string;
  updatedTs: string;
  createdBy: string;
  lastUpdatedBy: string;
}

/* Response */
export type FindSampleBatchesResponse = {
  data?: { findSampleBatches: SampleBatchLookup[] };
  errors?: GraphQlError[];
};

export type GetSampleBatchResponse = {
  data?: { getSampleBatch: SampleBatch };
  errors?: GraphQlError[];
};

export type CreateSampleBatchResponse = {
  data?: { createSampleBatch: SampleBatch };
  errors?: GraphQlError[];
};

export type FindCryoboxesResponse = {
  data?: { findCryoboxes: Cryobox[] };
  errors?: GraphQlError[];
};

export type CreateCryoboxResponse = {
  data?: { createCryobox: Cryobox };
  errors?: GraphQlError[];
};

export type GetCryoboxResponse = {
  data?: { getCryobox: Cryobox };
  errors?: GraphQlError[];
};

export type UpdateCryoboxResponse = {
  data?: { updateCryobox: Cryobox };
  errors?: GraphQlError[];
};

export type ListFreezersResponse = {
  data?: { listFreezers: Freezer[] };
  errors?: GraphQlError[];
};
