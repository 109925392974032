import React from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";

export interface AlertSuccessProps {
  title?: string;
  description?: string;
}

function AlertSuccess({ description, title }: AlertSuccessProps) {
  return (
    <Box mb={2}>
      <Alert severity="success">
        {title && (
          <>
            <strong>{title}</strong>{" "}
          </>
        )}{" "}
        {description}
      </Alert>
    </Box>
  );
}

export default AlertSuccess;
