import * as yup from "yup";
import { StringSchema } from "yup";
import { SampleTypeCodeEnum } from "../../core/api/lab.types";
import { MESSAGES } from "../../core/constants/forms.constants";
import {
  MAX_PRIMARY_BARCODES,
  MIN_PRIMARY_BARCODES,
} from "../../core/constants/lab.constants";
import { ALIQUOT_POSTFIXES } from "../../core/constants/lab.constants";

export const REGEX_ALIQUOT_BARCODE_BROAD = /^([0-9]{9})$/;

/* Extend yup */
declare module "yup" {
  interface StringSchema<
    T extends string | null | undefined = string | undefined,
    C = object
  > {
    matchesFirstPostfix(
      message?: yup.TestOptionsMessage
    ): yup.StringSchema<T, C>;
  }
}

yup.addMethod<StringSchema<any, any>>(
  yup.string,
  "matchesFirstPostfix",
  function (message) {
    return this.test("matchesFirstPostfix", message, function (value: string) {
      const parentValues = this.parent as any[];
      if (parentValues.length > 1 && value) {
        const firstValue = parentValues[0];
        return firstValue.slice(-2) === value.slice(-2);
      }
      return true;
    });
  }
);

/* 
Aliquot Schemas 
Validates a single aliquot barcode against its parent barcode, 
a regex for aliquot barcodes and checks the postfix based on sample type
given its rack position / column
*/
export const getAliquotSchema = (
  sampleType: SampleTypeCodeEnum | null,
  colIndex: number
) => {
  const POSTFIX = sampleType
    ? ALIQUOT_POSTFIXES[sampleType as string][colIndex]
    : null;
  const POSTFIX_REGEX = POSTFIX
    ? new RegExp(`\\b[0-9]{7}(${POSTFIX})\\b`, "g")
    : REGEX_ALIQUOT_BARCODE_BROAD;

  return yup
    .string()
    .trim()
    .required(MESSAGES.aliquotRequired)
    .test("match", MESSAGES.aliquotMismatch, function (val) {
      const parentRegex = new RegExp(
        `\\b(${this.parent.barcode})[0-9]{2}\\b`,
        "g"
      );
      return val ? parentRegex.test(val) : true;
    })
    .matches(REGEX_ALIQUOT_BARCODE_BROAD, MESSAGES.aliquotInvalid)
    .matches(POSTFIX_REGEX, MESSAGES.aliquotPostfixInvalid);
};

/* 
postfixesRegex
Converts array of postfixes into regex match
*/
export const postfixesRegex = (postfixArray: string[] | null) => {
  return postfixArray
    ? new RegExp(`\\b[0-9]{7}(${postfixArray.join("|")})\\b`, "g")
    : REGEX_ALIQUOT_BARCODE_BROAD;
};

export const getAliquotAnyColSchema = (
  sampleType: SampleTypeCodeEnum | null
) => {
  const POSSIBLE_POSTFIX = sampleType ? ALIQUOT_POSTFIXES[sampleType] : null;

  return yup
    .string()
    .trim()
    .required(MESSAGES.aliquotRequired)
    .matches(REGEX_ALIQUOT_BARCODE_BROAD, MESSAGES.aliquotInvalid)
    .matches(postfixesRegex(POSSIBLE_POSTFIX), MESSAGES.aliquotPostfixInvalid)
    .matchesFirstPostfix(MESSAGES.aliquotPostfixMismatch);
};

export const getAliquotAnyColOptionalSchema = (
  sampleType: SampleTypeCodeEnum | null
) => {
  const POSSIBLE_POSTFIX = sampleType ? ALIQUOT_POSTFIXES[sampleType] : null;

  return yup
    .string()
    .trim()
    .matches(REGEX_ALIQUOT_BARCODE_BROAD, MESSAGES.aliquotInvalid)
    .matches(postfixesRegex(POSSIBLE_POSTFIX), MESSAGES.aliquotPostfixInvalid);
};

export const getAliquotWithMismatchSchema = (
  sampleType: SampleTypeCodeEnum | null,
  colIndex: number
) => {
  return yup
    .string()
    .trim()
    .required(MESSAGES.aliquotRequired)
    .test("match", MESSAGES.aliquotMismatch, function (val) {
      if (colIndex === 0 && this.parent.aliquot1IDMismatch) {
        return true;
      } else if (colIndex === 1 && this.parent.aliquot2IDMismatch) {
        return true;
      } else if (colIndex === 0) {
        return val ? val === this.parent.aliquot1ID : true;
      } else if (colIndex === 1) {
        return val ? val === this.parent.aliquot2ID : true;
      }
      return true;
    })
    .matches(REGEX_ALIQUOT_BARCODE_BROAD, MESSAGES.aliquotInvalid);
};

export const getAliquotsSchema = (sampleType: SampleTypeCodeEnum | null) =>
  yup.object().shape({
    samples: yup
      .array()
      .min(MIN_PRIMARY_BARCODES, MESSAGES.primarySamplesEmpty)
      .max(MAX_PRIMARY_BARCODES, MESSAGES.primarySamplesFull)
      .of(
        yup.object().shape({
          aliquot1ID: getAliquotSchema(sampleType, 0),
          aliquot2ID: getAliquotSchema(sampleType, 1),
        })
      ),
  });

export const getAliquotsConfirmSchema = (
  sampleType: SampleTypeCodeEnum | null
) =>
  yup.object().shape({
    samples: yup
      .array()
      .min(MIN_PRIMARY_BARCODES, MESSAGES.primarySamplesEmpty)
      .max(MAX_PRIMARY_BARCODES, MESSAGES.primarySamplesFull)
      .of(
        yup.object().shape({
          aliquot1IDConfirm: getAliquotWithMismatchSchema(sampleType, 0),
          aliquot2IDConfirm: getAliquotWithMismatchSchema(sampleType, 1),
        })
      ),
  });

export const getAliquotsStatusSchema = (
  sampleType: SampleTypeCodeEnum | null
) =>
  yup.object().shape({
    samples: yup
      .array()
      .min(MIN_PRIMARY_BARCODES, MESSAGES.primarySamplesEmpty)
      .max(MAX_PRIMARY_BARCODES, MESSAGES.primarySamplesFull)
      .of(
        yup.object().shape({
          aliquot1Vol: yup
            .string()
            .trim()
            .required(MESSAGES.aliquot1VolRequired),
          aliquot2Vol: yup
            .string()
            .trim()
            .required(MESSAGES.aliquot1VolRequired),
        })
      ),
  });
