import React, { ReactNode } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export interface CryoboxTableProps {
  children: ReactNode;
}

function CryoboxTable({ children }: CryoboxTableProps) {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="cryobox table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Serial No.</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center" style={{ minWidth: 250 }}>
              Freezer
            </TableCell>
            <TableCell align="center">Shelf</TableCell>
            <TableCell align="center">Rack</TableCell>
            <TableCell align="center">Position</TableCell>
            <TableCell align="center">Slot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default CryoboxTable;
