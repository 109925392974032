import React from "react";
import { useFormikContext } from "formik";
import { FormInput, FormSelect, SelectOption } from "@omnigenbiodata/react";
import { useStyles } from "./component.styles";
import clsx from "classnames";
import { SampleFormValues } from "../../../../../../../../../../store/batchCreate/types";
import {
  ALIQUOT_STATUSES,
  StatusOptions,
} from "../../../../../../../../../../core/constants/lab.constants";
import { SampleTypeCodeEnum } from "../../../../../../../../../../core/api/lab.types";

interface AliquotStatusRowProps {
  index: number;
  sampleType: SampleTypeCodeEnum;
}

const AliquotStatusRow = ({ index, sampleType }: AliquotStatusRowProps) => {
  const formik = useFormikContext<{
    samples: SampleFormValues[];
  }>();
  const classes = useStyles();

  const { aliquot1IDConfirm, aliquot2IDConfirm } = formik.values.samples[index];
  const rowErrors: any = formik.errors.samples
    ? formik.errors.samples[index]
    : {};
  const rowTouched: any = formik.touched.samples
    ? formik.touched.samples[index]
    : {};

  return (
    <tr>
      <td
        align="center"
        className={clsx({
          [classes.leftCellRed]: sampleType === SampleTypeCodeEnum.SERUM,
          [classes.leftCellPurple]: sampleType !== SampleTypeCodeEnum.SERUM,
        })}
      >
        {index + 1}
      </td>
      <td>
        <FormInput
          label="Aliquot 1 ID"
          type="text"
          name={`samples.${index}.aliquot1IDConfirm`}
          error={rowErrors ? rowErrors.aliquot1IDConfirm : undefined}
          touched={aliquot1IDConfirm ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot1IDConfirm || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot1IDConfirm &&
            !aliquot1IDConfirm &&
            !rowTouched?.aliquot1IDConfirm
          }
        />
      </td>
      <td>
        <FormSelect
          label="Status 1"
          name={`samples.${index}.aliquot1Condition`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Condition"
          options={
            Object.keys(ALIQUOT_STATUSES).reduce(
              (previousValue: any[], key: keyof StatusOptions) => {
                return [
                  ...previousValue,
                  {
                    value: key,
                    label: ALIQUOT_STATUSES[key],
                  },
                ];
              },
              []
            ) as SelectOption[]
          }
          value={formik.values.samples[index].aliquot1Condition || ""}
        />
      </td>
      <td>
        <FormInput
          label="Vol 1"
          type="number"
          name={`samples.${index}.aliquot1Vol`}
          error={
            formik.errors?.samples &&
            (formik.errors?.samples[index] as SampleFormValues)
              ? (formik.errors?.samples[index] as SampleFormValues).aliquot1Vol
              : undefined
          }
          touched={
            formik.touched?.samples && formik.touched?.samples[index]
              ? (formik.touched?.samples[index]).aliquot1Vol
              : false
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={
            formik.values?.samples[index] &&
            formik.values?.samples[index].aliquot1Vol !== undefined
              ? (formik.values.samples[index].aliquot1Vol as string)
              : ""
          }
          min={0}
          step={0.1}
        />
      </td>
      <td>
        <FormInput
          label="Aliquot 2 ID"
          type="text"
          name={`samples.${index}.aliquot2IDConfirm`}
          error={rowErrors ? rowErrors.aliquot2IDConfirm : undefined}
          touched={aliquot2IDConfirm ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot2IDConfirm || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot2IDConfirm &&
            !aliquot2IDConfirm &&
            !rowTouched?.aliquot2IDConfirm
          }
        />
      </td>
      <td>
        <FormSelect
          label="Status 2"
          name={`samples.${index}.aliquot2Condition`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Condition"
          options={
            Object.keys(ALIQUOT_STATUSES).reduce(
              (previousValue: any[], key: keyof StatusOptions) => {
                return [
                  ...previousValue,
                  {
                    value: key,
                    label: ALIQUOT_STATUSES[key],
                  },
                ];
              },
              []
            ) as SelectOption[]
          }
          value={formik.values.samples[index].aliquot2Condition || ""}
        />
      </td>
      <td>
        <FormInput
          label="Vol 2"
          type="number"
          name={`samples.${index}.aliquot2Vol`}
          error={
            formik.errors?.samples &&
            (formik.errors?.samples[index] as SampleFormValues)
              ? (formik.errors?.samples[index] as SampleFormValues).aliquot2Vol
              : undefined
          }
          touched={
            formik.touched?.samples && formik.touched?.samples[index]
              ? (formik.touched?.samples[index]).aliquot2Vol
              : false
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={
            formik.values?.samples[index] &&
            formik.values?.samples[index].aliquot2Vol !== undefined
              ? (formik.values.samples[index].aliquot2Vol as string)
              : ""
          }
          min={0}
          step={0.1}
        />
      </td>
    </tr>
  );
};

export default AliquotStatusRow;
