import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import batchCreateReducer from "./batchCreate";
import cryoboxCreateReducer from "./cryoboxCreate";
import batchListReducer from "./batchList";
import batchReducer from "./batch";
import cryoboxReducer from "./cryobox";
import cryoboxesReducer from "./cryoboxList";
import freezerListReducer from "./freezerList";

const store = configureStore({
  reducer: {
    batchCreate: batchCreateReducer,
    batchList: batchListReducer,
    batch: batchReducer,
    cryoboxCreate: cryoboxCreateReducer,
    cryoboxList: cryoboxesReducer,
    cryobox: cryoboxReducer,
    freezerList: freezerListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
