import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import {
  ButtonRow,
  FormInput,
  FormSelect,
  Loader,
  SelectOption,
  FormCheckBox,
} from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../layout/Inner";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import {
  getCryoboxThunk,
  updateCryoboxThunk,
  hasErrorSelector,
  isBusySelector,
  responseSelector,
  isUpdatedSelector,
} from "../../../../../../store/cryobox";
import {
  listFreezersThunk,
  responseSelector as freezerResponseSelector,
} from "../../../../../../store/freezerList";
import CryoboxSummary from "../../components/CryoboxSummary";
import CryoboxGrid from "../../components/CryoboxGrid";
import { MESSAGES } from "../../../../../../core/constants/forms.constants";
import {
  Freezer,
  SampleTypeCodeEnum,
} from "../../../../../../core/api/lab.types";
import { AlertError, Panel, ScanEvent } from "../../../../../../components";
import Grid from "@material-ui/core/Grid";
import { AiOutlineNumber } from "react-icons/ai";
import EditAliquotDialog from "./component/EditAliquotDialog";
import AlertSuccess from "../../../../../../components/content/AlertSuccess";
import { getAliquotAnyColOptionalSchema } from "../../../../../../core/validation/aliquots.validation";
import Box from "@material-ui/core/Box";
import arrayUtils from "../../../../../../core/utils/arrays";

function CryoboxScene() {
  let { serialNo } = useParams<any>();
  const [selectedAliquot, setSelectedAliquot] = useState<number | undefined>();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);
  const isUpdated = useAppSelector(isUpdatedSelector);
  const freezerList = useAppSelector(freezerResponseSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCryoboxThunk(serialNo || ""));
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [serialNo, freezerList, dispatch]);

  const validationSchema = yup.lazy(() =>
    yup.object({
      freezerId: yup.string().required(MESSAGES.freezerIdRequired),
      freezerShelfNo: yup.string().required(MESSAGES.freezerShelfNoRequired),
      biobanked: yup.string(),
      shelfRackNo: yup.string().required(MESSAGES.shelfRackNoRequired),
      rackPositionNo: yup.string().required(MESSAGES.rackPositionNoRequired),
      aliquotPositions: yup
        .array()
        .of(
          getAliquotAnyColOptionalSchema(
            response?.sampleType as SampleTypeCodeEnum
          )
        )
        .min(1, MESSAGES.storageAliquotsRequired),
    })
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aliquotPositions: [...(response?.aliquotPositions || [])],
      freezerId: response?.freezer?.freezerId || "",
      freezerShelfNo: response?.freezerShelfNo || "",
      bioBanked: response?.bioBanked || "",
      shelfRackNo: response?.shelfRackNo || "",
      rackPositionNo: response?.rackPositionNo || "",
      serialNo: response?.serialNo || "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateCryoboxThunk(values));
    },
  });

  const showLoader = isBusy;
  const showGetError = hasError && !isBusy;
  const showGetResult = !isBusy && !hasError && response;

  const widthInt = response?.width;
  const heightInt = response?.height;
  const aliquotErrors =
    formik.errors.aliquotPositions &&
    typeof formik.errors.aliquotPositions !== "string"
      ? formik.errors.aliquotPositions
      : [];
  const aliquotValues = formik.values.aliquotPositions as string[];
  const totalValues = aliquotValues.length;

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Cryobox Details
      </Typography>
      {showGetError && (
        <AlertError
          title="Error"
          description="Could not load the selected sample batch"
        />
      )}
      {isUpdated && (
        <AlertSuccess
          title="Updated"
          description={`You have successfully updated cryobox ${response?.serialNo}`}
        />
      )}

      {showGetResult && (
        <FormikProvider value={formik}>
          <Form>
            <FieldArray name="aliquotPositions">
              {({ remove, replace, push }) => (
                <>
                  {selectedAliquot === undefined && (
                    <ScanEvent
                      data-testid="scanner"
                      onScan={(scanCode: string) => {
                        if (scanCode && !aliquotValues.includes(scanCode)) {
                          push(scanCode);
                        }
                      }}
                    />
                  )}
                  <CryoboxSummary
                    sampleType={response.sampleType}
                    serialNo={response.serialNo}
                    createdTs={response.createdTs}
                  />
                  <Panel mb={6} title="Storage Location">
                    <FormSelect
                      label="Freezer"
                      name={`freezerId`}
                      error={formik.errors.freezerId}
                      touched={formik.touched.freezerId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Freezer"
                      options={
                        freezerList
                          ? (freezerList.map((freezer: Freezer) => ({
                              value: freezer.freezerId,
                              label: `${freezer.name} (${freezer.serialNo})`,
                            })) as SelectOption[])
                          : []
                      }
                      value={formik.values.freezerId}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormInput
                          label="Freezer Shelf No."
                          type="text"
                          name={`freezerShelfNo`}
                          error={formik.errors.freezerShelfNo}
                          touched={formik.touched.freezerShelfNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.freezerShelfNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormInput
                          label="Shelf Rack No."
                          type="text"
                          name={`shelfRackNo`}
                          error={formik.errors.shelfRackNo}
                          touched={formik.touched.shelfRackNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.shelfRackNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormInput
                          label="Rack Position No."
                          type="text"
                          name={`rackPositionNo`}
                          error={formik.errors.rackPositionNo}
                          touched={formik.touched.rackPositionNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rackPositionNo}
                          startAdornment={<AiOutlineNumber />}
                        />
                      </Grid>
                    </Grid>
                    <Box pl={2}>
                      <FormCheckBox
                        label="Biobanked"
                        name={`bioBanked`}
                        error={formik.errors.bioBanked}
                        touched={formik.touched.bioBanked}
                        value={formik.values.bioBanked}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Panel>
                  <Panel mb={6} title="Aliquot Positions">
                    <CryoboxGrid
                      width={widthInt}
                      height={heightInt}
                      onRemove={remove}
                      onSelect={(index: number) => {
                        setSelectedAliquot(index);
                      }}
                      values={aliquotValues}
                      errors={aliquotErrors}
                      allowRemoveLast={false}
                    />
                  </Panel>
                  <ButtonRow
                    buttonSize="small"
                    forwardLabel="Save Cryobox"
                    showForward={
                      formik.isValid && totalValues > 0 && freezerList !== null
                    }
                  />
                  {selectedAliquot !== undefined && (
                    <EditAliquotDialog
                      index={selectedAliquot}
                      value={
                        selectedAliquot !== undefined
                          ? aliquotValues[selectedAliquot]
                          : ""
                      }
                      isOpen={selectedAliquot !== undefined ? true : false}
                      onClose={() => {
                        setSelectedAliquot(undefined);
                      }}
                      onSave={(targetValue: string, targetIndex: number) => {
                        setSelectedAliquot(undefined);
                        formik.setFieldValue(
                          "aliquotPositions",
                          arrayUtils
                            .given(aliquotValues)
                            .pushToIndex(targetIndex, targetValue)
                            .trimEmpties()
                            .out()
                        );
                      }}
                      onRemove={(targetIndex: number) => {
                        formik.setFieldValue(
                          "aliquotPositions",
                          arrayUtils
                            .given(aliquotValues)
                            .replaceAtIndex(targetIndex, "")
                            .trimEmpties()
                            .out()
                        );
                        setSelectedAliquot(undefined);
                      }}
                    />
                  )}
                </>
              )}
            </FieldArray>
          </Form>
        </FormikProvider>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default CryoboxScene;
