import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from "../../core/api/lab.api";
import { ListFreezersResponse } from "../../core/api/lab.types";

/*  List Freezers */
export const listFreezersThunk = createAsyncThunk(
  "freezerList/listFreezers",
  async (values: undefined, { rejectWithValue }: any) => {
    const response: ListFreezersResponse = await labApi.listFreezers();
    if (response.data?.listFreezers) {
      return response.data?.listFreezers;
    }
    return rejectWithValue(response.errors);
  }
);
