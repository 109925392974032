import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Loader } from "@omnigenbiodata/react";
import BatchSearch from "../../../Home/components/BatchSearch";
import InnerLayout from "../../../../../../layout/Inner";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import {
  findSampleBatchesThunk,
  hasErrorSelector,
  isBusySelector,
  searchTermSelector,
  responseSelector,
  setValues,
} from "../../../../../../store/batchList";
import ROUTES from "../../../../../../core/constants/routes.constants";
import BatchRow from "./components/BatchRow";
import BatchTable from "./components/BatchTable";
import AlertNoResults from "./components/AlertNoResults";
import { BatchListFormValues } from "../../../../../../store/batchList/types";
import { SampleBatchLookup } from "../../../../../../core/api/lab.types";
import { AlertError } from "../../../../../../components";

function ListScene() {
  const searchTerm = useAppSelector(searchTermSelector);
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const batches = useAppSelector(responseSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchTerm) {
      dispatch(findSampleBatchesThunk(searchTerm || ""));
    }
  }, [searchTerm, dispatch]);

  const handleBatchSearch = (values: BatchListFormValues) => {
    dispatch(setValues(values));
  };

  const showLoader = isBusy;
  const showError = hasError && !isBusy;
  const showResults = !isBusy && !hasError && batches && batches.length > 0;
  const showNoResults = !isBusy && !hasError && batches && batches.length === 0;

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Sample Batch Results
      </Typography>
      <BatchSearch onSearch={handleBatchSearch} searchTerm={searchTerm || ""} />
      {showError && (
        <AlertError
          title="Something went wrong"
          description="There was a problem returning sample"
        />
      )}
      {showNoResults && <AlertNoResults />}
      {showResults && (
        <BatchTable>
          {batches.map((batchRow: SampleBatchLookup) => (
            <BatchRow
              key={batchRow.batchId}
              batchId={batchRow.batchId}
              createdTs={batchRow.createdTs}
              sampleType={batchRow.sampleType}
              editLink={ROUTES.batchesEdit.replace(
                ":batchid",
                batchRow.batchId
              )}
            />
          ))}
        </BatchTable>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default ListScene;
