import React, { ReactNode } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export interface BatchTableProps {
  children: ReactNode;
}

function BatchTable({ children }: BatchTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ minWidth: 300 }}>
              Batch ID
            </TableCell>
            <TableCell align="center" style={{ width: 90 }}>
              Type
            </TableCell>
            <TableCell align="center" style={{ width: 250 }}>
              Created
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default BatchTable;
