import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { GrDocumentCsv } from "react-icons/gr";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import { Loader } from "@omnigenbiodata/react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import {
  findCryoboxesThunk,
  hasErrorSelector,
  isBusySelector,
  searchTermSelector,
  responseSelector,
  setValues,
  freezerIdSelector,
  bioBankedSelector,
} from "../../../../../../store/cryoboxList";
import { responseSelector as freezerResponseSelector } from "../../../../../../store/freezerList";
import InnerLayout from "../../../../../../layout/Inner";
import StorageSearch from "../../../Home/components/StorageSearch";
import AlertNoResults from "./components/AlertNoResults";
import CryoboxTable from "./components/CryoboxTable";
import CryoboxRow from "./components/CryoboxRow";
import ROUTES from "../../../../../../core/constants/routes.constants";
import { Cryobox } from "../../../../../../core/api/lab.types";
import { AlertError } from "../../../../../../components";
import { listFreezersThunk } from "../../../../../../store/freezerList";

function ListScene() {
  const searchTerm = useAppSelector(searchTermSelector);
  const freezerId = useAppSelector(freezerIdSelector);
  const bioBanked = useAppSelector(bioBankedSelector);

  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const cryoboxes = useAppSelector(responseSelector);
  const freezerList = useAppSelector(freezerResponseSelector);
  const [page, setPage] = useState(0);
  const resultPerPage = 20;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchTerm || freezerId || bioBanked) {
      setPage(0);
      dispatch(
        findCryoboxesThunk({
          searchTerm: searchTerm || "",
          freezerId: freezerId || "",
          bioBanked: bioBanked || "",
        })
      );
    }
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [searchTerm, freezerId, bioBanked, freezerList, dispatch]);

  const handleCryoboxSearch = (values: any) => {
    dispatch(setValues(values));
  };

  const handlePagination = (event: any, page: any) => {
    setPage(page);
  };

  const showLoader = isBusy;
  const showError = hasError && !isBusy;
  const showResults = !isBusy && !hasError && cryoboxes && cryoboxes.length > 0;
  const showNoResults =
    !isBusy && !hasError && cryoboxes && cryoboxes.length === 0;

  const pages = _.chunk(cryoboxes, resultPerPage);

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Cryobox Results
      </Typography>
      <StorageSearch
        showTitle={false}
        onSearch={handleCryoboxSearch}
        searchTerm={searchTerm || ""}
        freezerId={freezerId || ""}
        freezerList={freezerList || []}
        bioBanked={bioBanked || ""}
      />
      {showError && (
        <AlertError
          title="Something went wrong"
          description="There was an problem returning cryoboxes. Please try again. If the problem
      persists please contact us."
        />
      )}
      {showNoResults && <AlertNoResults />}
      {showResults && (
        <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Typography
            style={{
              flex: "1 1 100%",
            }}
            variant="body1"
            component="div"
          >
            Showing {cryoboxes.length}{" "}
            {cryoboxes.length > 1 ? "cryoboxes" : "cryobox"}
          </Typography>

          <Button
            component={CSVLink}
            filename={"cryoboxes.csv"}
            data={cryoboxes.reduce((previousArr: any[], cryobox) => {
              const perAliquotRows = cryobox.aliquotPositions.map(
                (aliquot, aliquotIndex) => {
                  return {
                    FreezerName: cryobox.freezer.name,
                    CryoboxID: cryobox.serialNo,
                    Shelf: cryobox.freezerShelfNo,
                    Rack: cryobox.shelfRackNo,
                    Position: cryobox.rackPositionNo,
                    DateStored: cryobox.createdTs,
                    Biobanked: cryobox.bioBanked,
                    SampleType: cryobox.sampleType,
                    aliquotPosition: aliquotIndex + 1,
                    aliquotID: aliquot,
                  };
                }
              );

              return [...previousArr, ...perAliquotRows];
            }, [])}
            startIcon={<GrDocumentCsv />}
          >
            Download
          </Button>
        </Toolbar>
      )}
      {showResults && (
        <Paper>
          <CryoboxTable>
            {pages[page].map((cryoboxRow: Cryobox, rowNum: number) => (
              <CryoboxRow
                key={`${cryoboxRow.serialNo}-${rowNum}`}
                serialNo={cryoboxRow.serialNo}
                sampleType={cryoboxRow.sampleType}
                freezerName={cryoboxRow.freezer.name}
                freezerShelfNo={cryoboxRow.freezerShelfNo}
                shelfRackNo={cryoboxRow.shelfRackNo}
                rackPositionNo={cryoboxRow.rackPositionNo}
                aliquotPositions={cryoboxRow.aliquotPositions}
                searchTerm={searchTerm}
                editLink={ROUTES.cryoboxEdit.replace(
                  ":serialNo",
                  cryoboxRow.serialNo
                )}
              />
            ))}
          </CryoboxTable>
          {pages.length > 1 && (
            <TablePagination
              component="div"
              count={cryoboxes.length}
              rowsPerPageOptions={[]}
              rowsPerPage={resultPerPage}
              page={page}
              onPageChange={handlePagination}
            />
          )}
        </Paper>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default ListScene;
