import React, { useMemo } from "react";
import {
  RiCheckboxBlankCircleLine,
  RiBarcodeLine,
  RiAlertLine,
} from "react-icons/ri";
import { FaTrashAlt } from "react-icons/fa";
import clsx from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { theme } from "@omnigenbiodata/react";
import { useStyles } from "./component.styles";
import Box from "@material-ui/core/Box";

interface ScannedCellProps {
  allowRemoval?: boolean;
  hasError?: boolean | string;
  onRemove?: (index: number) => void;
  onSelect?: (index: number) => void;
  position: number;
  totalPositions?: number;
  value?: string;
}

function ScannedCell({
  allowRemoval = true,
  hasError = false,
  onRemove,
  onSelect,
  position,
  totalPositions = 0,
  value,
}: ScannedCellProps) {
  const css = useStyles();

  const isLastScanned = useMemo(
    () => position === totalPositions,
    [position, totalPositions]
  );

  return (
    <div
      key={`scanned-cell-${position}`}
      className={clsx(css.aliquotColumn, {
        [css.aliquotColumnEmpty]: !value && !hasError,
        [css.aliquotColumnFull]: value && !hasError,
        [css.aliquotColumnError]: hasError,
        [css.isSelectable]: onSelect && value !== undefined,
      })}
      onClick={() => {
        if (onSelect && value !== undefined) {
          onSelect(position - 1);
        }
      }}
    >
      <div className={clsx(css.aliquotIndex)}>{position}</div>

      <div className={clsx(css.aliquotContent)}>
        {value && (
          <Tooltip
            arrow
            title={
              <Box p={2} alignItems="middle">
                <Typography
                  variant="body1"
                  component="span"
                  className={css.tooltipTypog}
                >
                  {!hasError && (
                    <RiBarcodeLine size={25} className={css.tooltipIcon} />
                  )}
                  {hasError && (
                    <RiAlertLine size={25} className={css.tooltipIcon} />
                  )}{" "}
                  {value}
                </Typography>
              </Box>
            }
            aria-label={value as any}
            placement="top"
          >
            <div>
              {(!isLastScanned || !allowRemoval) && value && (
                <IconButton aria-label="Valid Aliquot" size="small">
                  <RiBarcodeLine
                    color={theme.palette.common.white}
                    title={value as string}
                    fontSize={30}
                    alignmentBaseline="middle"
                  />
                </IconButton>
              )}
              {isLastScanned && allowRemoval && (
                <IconButton
                  aria-label="Delete Invalid Tube"
                  size="small"
                  onClick={() => {
                    if (onRemove) {
                      onRemove(position - 1);
                    }
                  }}
                >
                  <FaTrashAlt
                    fontSize={20}
                    color={theme.palette.common.white}
                  />
                </IconButton>
              )}
            </div>
          </Tooltip>
        )}
        {!value && <RiCheckboxBlankCircleLine color="grey" />}
      </div>
    </div>
  );
}

export default ScannedCell;
