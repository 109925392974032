import React from "react";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ButtonRow, FormInput, Loader, useAuth } from "@omnigenbiodata/react";
import MainLayout from "../../../../layout/Main";
import { MESSAGES } from "../../../../core/constants/forms.constants";
import ROUTES from "../../../../core/constants/routes.constants";

const validationSchema = yup.object().shape({
  email: yup.string().required(MESSAGES.emailRequired),
});

function ResetPassword() {
  const {
    forgotPasswordHasError,
    isBusy,
    forgotPassword,
    forgotPasswordEmail,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values: any) => {
      forgotPassword(values.email);
    },
  });

  return (
    <>
      {forgotPasswordEmail && <Redirect to={ROUTES.authSubmitResetPassword} />}
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Forgotten password?
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please enter your email address below:
            </Typography>
          </Box>
          {forgotPasswordHasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Unexpected Error</strong>
                </Typography>
                <Typography variant="body1">
                  Your password could not be reset.
                </Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.email}
              name="email"
              label="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              value={formik.values.email}
            />
          </Box>
          <ButtonRow forwardLabel="Confirm" buttonSize="small" />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default ResetPassword;
