import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";
import { Cryobox } from "../../core/api/lab.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.cryoboxList.errors && state.cryoboxList.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.cryoboxList.status === StoreStatus.BUSY;

export const searchTermSelector = (state: RootState): string | null =>
  state.cryoboxList.values?.searchTerm || null;

export const freezerIdSelector = (state: RootState): string | null =>
  state.cryoboxList.values?.freezerId || null;

export const bioBankedSelector = (state: RootState): string | null =>
  state.cryoboxList.values?.bioBanked || null;

export const responseSelector = (state: RootState): Cryobox[] | null =>
  state.cryoboxList.response || null;
