import { createSlice } from "@reduxjs/toolkit";
import { CryoboxState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { getCryoboxThunk, updateCryoboxThunk } from "./thunks";

// Initial State
const initialState: CryoboxState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  isUpdated: null,
};

// Slice
export const cryoboxSlice = createSlice({
  name: "cryobox",
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [getCryoboxThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
      state.isUpdated = null;
    },
    [getCryoboxThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    },
    [getCryoboxThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
    [updateCryoboxThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
      state.isUpdated = null;
    },
    [updateCryoboxThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.errors = null;
      state.response = action.payload;
      state.isUpdated = true;
    },
    [updateCryoboxThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.isUpdated = false;
    },
  },
});

// Action creators
export const { reset } = cryoboxSlice.actions;
export * from "./thunks";
export * from "./selectors";
export default cryoboxSlice.reducer;
