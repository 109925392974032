import { createSlice } from "@reduxjs/toolkit";
import { BatchState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { getSampleBatchThunk } from "./thunks";

// Initial State
const initialState: BatchState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
};

// Slice
export const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [getSampleBatchThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [getSampleBatchThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    },
    [getSampleBatchThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
  },
});

// Action creators
export const { reset } = batchSlice.actions;
export * from "./thunks";
export * from "./selectors";
export default batchSlice.reducer;
