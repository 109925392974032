import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { ButtonRow, FormStepper, Loader } from "@omnigenbiodata/react";
import { Redirect } from "react-router-dom";
import InnerLayout from "../../../../../../../../layout/Inner";
import FORMS from "../../../../../../../../core/constants/forms.constants";
import {
  DEFAULT_SERUM_ALIQUOT_VOLUME,
  DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME,
} from "../../../../../../../../core/constants/lab.constants";
import { Panel } from "../../../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store";
import {
  samplesSelector,
  sampleTypeSelector,
  hasErrorSelector,
  isBusySelector,
  responseSelector,
} from "../../../../../../../../store/batchCreate/selectors";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { AliquotsStatusList, AliquotsStatusRow } from "./components";
import { createSampleBatchThunk } from "../../../../../../../../store/batchCreate";
import {
  CreateSampleBatchInput,
  SampleTypeCodeEnum,
} from "../../../../../../../../core/api/lab.types";
import { SampleFormValues } from "../../../../../../../../store/batchCreate/types";
import { getAliquotsStatusSchema } from "../../../../../../../../core/validation/aliquots.validation";

function AliquotsStatusScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      sampleType,
      samples: samples
        ? samples.map((sample) => {
            return {
              ...sample,
              aliquot1Vol: `${
                sampleType === SampleTypeCodeEnum.WHOLE
                  ? DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME
                  : DEFAULT_SERUM_ALIQUOT_VOLUME
              }`,
              aliquot2Vol: `${
                sampleType === SampleTypeCodeEnum.WHOLE
                  ? DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME
                  : DEFAULT_SERUM_ALIQUOT_VOLUME
              }`,
              aliquot1Condition: "SAT",
              aliquot2Condition: "SAT",
            };
          })
        : null,
    },
    validationSchema: getAliquotsStatusSchema(sampleType),
    onSubmit: (values) => {
      dispatch(
        createSampleBatchThunk({
          sampleType: values.sampleType,
          samples: values.samples
            ? values.samples.map((sample, rowIndex) => {
                return {
                  barcode: sample.barcode,
                  status: sample.condition,
                  volume: sample.volume,
                  aliquots: [
                    {
                      barcode: sample.aliquot1ID,
                      mismatch: sample.aliquot1IDMismatch,
                      status: sample.aliquot1Condition,
                      volume: sample.aliquot1Vol,
                    },
                    {
                      barcode: sample.aliquot2ID,
                      mismatch: sample.aliquot2IDMismatch,
                      status: sample.aliquot2Condition,
                      volume: sample.aliquot2Vol,
                    },
                  ],
                };
              })
            : [],
        } as CreateSampleBatchInput)
      );
    },
  });

  if (response) {
    return <Redirect to={ROUTES.batchesCreateComplete} />;
  }

  if (!sampleType || !samples) {
    return <Redirect to={ROUTES.batchesCreate} />;
  }

  return (
    <>
      <InnerLayout>
        <FormikProvider value={formik}>
          <Form>
            <FieldArray name="samples">
              {() => (
                <>
                  <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    paragraph
                  >
                    Sample Processing
                  </Typography>
                  <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={4} />
                  <Panel
                    mb={6}
                    title="Aliquot Status"
                    intro="Please record the status and volume of each aliquot sample"
                  >
                    {hasError && !isBusy && (
                      <Box mb={2}>
                        <Alert severity="error">
                          There was an unexpected problem submitting the samples
                          and aliquots below.
                        </Alert>
                      </Box>
                    )}
                    {formik.values?.samples &&
                      formik.values?.samples?.length > 0 && (
                        <AliquotsStatusList>
                          {formik.values.samples.map(
                            (sample: SampleFormValues, index: number) => (
                              <AliquotsStatusRow
                                key={`${sample.barcode}-${index}`}
                                index={index}
                                sampleType={sampleType}
                              />
                            )
                          )}
                        </AliquotsStatusList>
                      )}
                  </Panel>
                  <ButtonRow
                    showForward={formik.isValid}
                    forwardLabel="Submit"
                    buttonSize="small"
                  />
                </>
              )}
            </FieldArray>
          </Form>
        </FormikProvider>
      </InnerLayout>
      <Loader isVisible={isBusy} label={"Submitting"} />
    </>
  );
}

export default AliquotsStatusScene;
