import React, { useEffect, useMemo, useRef } from "react";
// import Typography from "@material-ui/core/Typography";
import { useFormikContext } from "formik";
import { FormInput, theme } from "@omnigenbiodata/react";
import { RiBarcodeLine } from "react-icons/ri";
import { useStyles } from "./component.styles";
import clsx from "classnames";
import { SampleFormValues } from "../../../../../../../../../../store/batchCreate/types";
import Typography from "@material-ui/core/Typography";
import EndAdornment from "./components/EndAdornment";
import { SampleTypeCodeEnum } from "../../../../../../../../../../core/api/lab.types";

interface CryoVialRowProps {
  index: number;
  sampleType: SampleTypeCodeEnum;
  onDelete: (index: number, colIndex: number) => void;
}

const CryoVialRow = ({ index, sampleType, onDelete }: CryoVialRowProps) => {
  const formik = useFormikContext<{
    samples: SampleFormValues[];
  }>();
  const classes = useStyles();
  const myRef = useRef<null | HTMLTableRowElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [myRef, formik.values.samples]);

  const rowActive = useMemo(() => {
    return (
      formik.values.samples
        .filter((sample) => {
          return (
            (!sample.aliquot1ID && !sample.aliquot2ID) ||
            (sample.aliquot1ID && !sample.aliquot2ID)
          );
        }, [])
        .map((sample) => sample.barcode)
        .shift() === (formik.values?.samples[index] as SampleFormValues).barcode
    );
  }, [formik.values, index]);

  const { barcode, aliquot1ID, aliquot2ID } = formik.values.samples[index];
  const rowErrors: any = formik.errors.samples
    ? formik.errors.samples[index]
    : {};
  const rowTouched: any = formik.touched.samples
    ? formik.touched.samples[index]
    : {};
  const rowIsLocked = aliquot2ID && aliquot1ID;
  const prevRowErrors: any =
    formik.errors.samples && formik.errors.samples[index - 1]
      ? formik.errors.samples[index - 1]
      : {};

  return (
    <tr
      ref={rowActive ? myRef : undefined}
      className={clsx({
        [classes.newCell]: rowActive,
        [classes.pendingCell]:
          (!rowActive && !rowIsLocked) ||
          prevRowErrors.aliquot1ID ||
          prevRowErrors.aliquot2ID,
      })}
    >
      <td
        align="center"
        className={clsx({
          [classes.leftCellRed]: sampleType === SampleTypeCodeEnum.SERUM,
          [classes.leftCellPurple]: sampleType !== SampleTypeCodeEnum.SERUM,
        })}
      >
        {index + 1}
      </td>
      <td align="center">
        <Typography variant="body1" component="p" align="center">
          <RiBarcodeLine
            fontSize={25}
            color={theme.palette.grey[500]}
            style={{ verticalAlign: "middle", marginRight: 5 }}
          />
          {barcode}
        </Typography>
      </td>
      <td>
        <FormInput
          label="Aliquot 1 ID"
          type="text"
          name={`samples.${index}.aliquot1ID`}
          error={rowErrors ? rowErrors.aliquot1ID : undefined}
          touched={aliquot1ID ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot1ID || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot1ID && !aliquot1ID && !rowTouched?.aliquot1ID
          }
          startAdornment={
            <RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />
          }
          endAdornment={
            <EndAdornment
              value={aliquot1ID}
              touched={rowTouched && rowTouched.aliquot1ID}
              errors={rowErrors && rowErrors.aliquot1ID}
              isActive={
                rowActive &&
                !aliquot1ID &&
                !aliquot2ID &&
                !prevRowErrors.aliquot1ID &&
                !prevRowErrors.aliquot2ID
              }
              index={index}
              columnIndex={0}
              onDelete={onDelete}
            />
          }
        />
      </td>
      <td>
        <FormInput
          label="Aliquot 2 ID"
          type="text"
          name={`samples.${index}.aliquot2ID`}
          error={rowErrors ? rowErrors.aliquot2ID : undefined}
          touched={aliquot2ID ? true : false}
          onChange={formik.handleChange}
          onBlur={() => undefined}
          value={formik.values.samples[index].aliquot2ID || ""}
          showErrorMessage={false}
          readOnly
          disabled={
            !rowErrors?.aliquot2ID && !aliquot2ID && !rowTouched?.aliquot2ID
          }
          startAdornment={
            <RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />
          }
          endAdornment={
            <EndAdornment
              value={aliquot2ID}
              touched={rowTouched && rowTouched.aliquot2ID}
              errors={rowErrors && rowErrors.aliquot2ID}
              isActive={
                rowActive &&
                aliquot1ID &&
                !rowErrors.aliquot1ID &&
                !aliquot2ID &&
                !prevRowErrors.aliquot1ID &&
                !prevRowErrors.aliquot2ID
              }
              index={index}
              columnIndex={1}
              onDelete={onDelete}
            />
          }
        />
      </td>
    </tr>
  );
};

export default CryoVialRow;
