import React from "react";
import { RiBarcodeLine, RiArrowRightLine } from "react-icons/ri";
import * as yup from "yup";
import { useFormik } from "formik";
import { FormInput } from "@omnigenbiodata/react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { MESSAGES } from "../../../../../../core/constants/forms.constants";
import { Panel } from "../../../../../../components";

interface BatchSearchProps {
  onSearch: (values: object) => void;
  buttonLabel?: string;
  searchTerm?: string;
}

function BatchSearch({
  searchTerm = "",
  buttonLabel = "Search",
  onSearch,
}: BatchSearchProps) {
  const validationSchema = yup.object({
    searchTerm: yup
      .string()
      .required(MESSAGES.sampleSearchTermRequired)
      .min(7, "Invalid barcode")
      .max(9, "Invalid barcode"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchTerm,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      onSearch(values);
    },
  });

  return (
    <Panel data-testid="sampleSearch" mb={2}>
      <Typography component="h2" paragraph variant="h6">
        Sample Batch Search
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <FormInput
              label="Primary ID"
              type="text"
              name={`searchTerm`}
              error={formik.errors.searchTerm}
              touched={formik.touched.searchTerm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.searchTerm}
              margin="none"
              startAdornment={<RiBarcodeLine />}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              type="submit"
              variant="outlined"
              size="small"
              color="default"
              fullWidth
            >
              {buttonLabel} <RiArrowRightLine />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
}

export default BatchSearch;
