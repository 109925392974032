import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Loader } from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../layout/Inner";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import {
  getSampleBatchThunk,
  hasErrorSelector,
  isBusySelector,
  responseSelector,
} from "../../../../../../store/batch";
import ROUTES from "../../../../../../core/constants/routes.constants";
import { Sample } from "../../../../../../core/api/lab.types";
import { AlertError } from "../../../../../../components";

import SamplesTable from "./components/SamplesTable";
import SamplesRow from "./components/SamplesRow";
import BatchSummary from "./components/BatchSummary";

function ProcessingResult() {
  let { batchid } = useParams<any>();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSampleBatchThunk(batchid || ""));
  }, [batchid, dispatch]);

  const showLoader = isBusy;
  const showError = hasError && !isBusy;
  const showResults = !isBusy && !hasError && response;

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Sample Batch Details
      </Typography>
      {showError && (
        <AlertError
          title="Error"
          description="Could not load the selected sample batch"
        />
      )}
      {showResults && (
        <>
          <BatchSummary
            sampleType={response.sampleType}
            batchId={response.batchId}
            createdTs={response.createdTs}
          />
          <SamplesTable>
            {response.samples.map((sample: Sample) => (
              <SamplesRow
                key={sample.barcode}
                barcode={sample.barcode}
                volume={sample.volume}
                status={sample.status}
                aliquots={sample.aliquots}
              />
            ))}
          </SamplesTable>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                to={ROUTES.batches}
                size="small"
                component={Link}
                fullWidth
              >
                Search Again
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Loader isVisible={showLoader} />
    </InnerLayout>
  );
}

export default ProcessingResult;
