import React from "react";
import { Route, Switch } from "react-router-dom";
import SampleTypeScene from "./scenes/SampleType";
import PrimaryTubesScene from "./scenes/PrimaryTubes";
import CryoVialsScene from "./scenes/CryoVials";
import StartAliquottingScene from "./scenes/StartAliquotting";
import AliquotsConfirmScene from "./scenes/AliquotsConfirm";
import AliquotsStatusScene from "./scenes/AliquotsStatus";
import CompleteScene from "./scenes/Complete";
import ROUTES from "../../../../../../core/constants/routes.constants";

function CreateScene() {
  return (
    <Switch>
      <Route path={ROUTES.batchesCreateComplete} component={CompleteScene} />
      <Route
        path={ROUTES.batchesCreateAliquotsStatus}
        component={AliquotsStatusScene}
      />
      <Route
        path={ROUTES.batchesCreateAliquots2}
        component={AliquotsConfirmScene}
      />
      <Route
        path={ROUTES.batchesCreateAliquotting}
        component={StartAliquottingScene}
      />
      <Route path={ROUTES.batchesCreateCryoVials} component={CryoVialsScene} />
      <Route
        path={ROUTES.batchesCreatePrimaryTubes}
        component={PrimaryTubesScene}
      />
      <Route path={ROUTES.batches} component={SampleTypeScene} />
    </Switch>
  );
}

export default CreateScene;
