import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthRoute } from "@omnigenbiodata/react";
import ROUTES from "../core/constants/routes.constants";
import Authentication from "./Authentication";
import Portal from "./Portal";

function Scenes() {
  return (
    <Switch>
      <Route path={ROUTES.auth} component={Authentication} />
      <AuthRoute path={ROUTES.portal} component={Portal} />
      <Redirect to={ROUTES.portal} />
    </Switch>
  );
}

export default Scenes;
