import { createSlice } from "@reduxjs/toolkit";
import { BatchListState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { findSampleBatchesThunk } from "./thunks";

// Initial State
const initialState: BatchListState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
};

// Slice
export const batchListSlice = createSlice({
  name: "batchList",
  initialState,
  reducers: {
    setValues: (state, action) => ({
      ...state,
      values: action.payload,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [findSampleBatchesThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [findSampleBatchesThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    },
    [findSampleBatchesThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
  },
});

// Action creators
export const { reset, setValues } = batchListSlice.actions;
export * from "./thunks";
export * from "./selectors";
export default batchListSlice.reducer;
