import React from "react";
import { GiTestTubes } from "react-icons/gi";
import { BsBookshelf } from "react-icons/bs";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ROUTES from "../../../../../../core/constants/routes.constants";
import { Link } from "react-router-dom";

function HomeOptions() {
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            component={Link}
            to={ROUTES.batchesCreate}
            startIcon={<GiTestTubes size={40} />}
          >
            Sample Processing
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            component={Link}
            to={ROUTES.cryoboxCreate}
            startIcon={<BsBookshelf size={40} />}
          >
            Sample Storage
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeOptions;
