export type StatusOptions = {
  [key: string]: string;
};

export const PRIMARY_STATUSES: StatusOptions = {
  SAT: "Satisfactory",
  EQF: "Equipment failure",
  LBE: "Laboratory error",
  PST: "Processed after specified time",
  CLT: "Clotted",
  CTM: "Contaminated",
  HEM: "Hemolyzed",
  ICT: "Icteric",
  LIP: "Lipemic",
  LYS: "Lysed",
  SNP: "Sample Not Processed",
  BKV: "Broken or cracked vial",
  DMG: "Damaged container",
  EXP: "Expired",
  INT: "Incorrect Tube",
  LKD: "Leaked",
  FRO: "Frozen",
  TNO: "Temperature Not Optimal",
  SHV: "Short Volume",
  SNC: "Sample Not Collected",
};

export const DEFAULT_PRIMARY_STATUS = "SAT";

export const ALIQUOT_STATUSES: StatusOptions = {
  SAT: "Satisfactory",
  CLT: "Clotted",
  CTM: "Contaminated",
  HEM: "Hemolyzed",
  SHV: "Short volume",
};
export const DEFAULT_ALIQUOT_STATUS = "SAT";

export const ALIQUOT_POSTFIXES: any = {
  WHOLE: ["01", "02"],
  SERUM: ["03", "04"],
  PLASMA: ["05", "06"],
};
export const MIN_PRIMARY_BARCODES = 1;
export const MAX_PRIMARY_BARCODES = 10;
export const PRIMARY_BARCODE_LENGTH = 7;
export const ALIQUOT_BARCODE_LENGTH = 9;

export const DEFAULT_PRIMARY_VOLUME = 6;
export const DEFAULT_WHOLE_BLOOD_ALIQUOT_VOLUME = 1.8;
export const DEFAULT_SERUM_ALIQUOT_VOLUME = 1;
export const DEFAULT_PLASMA_ALIQUOT_VOLUME = 1;

export const REGEX_PRIMARY_BARCODE_BROAD = /^([0-9]{7})$/;
