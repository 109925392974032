import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Formatter } from "@omnigenbiodata/react";
import { Grid } from "@material-ui/core";
import { SampleTypeCodeEnum } from "../../../../../../core/api/lab.types";
import { Panel } from "../../../../../../components";

export interface CryoboxSummaryProps {
  serialNo?: string;
  sampleType?: SampleTypeCodeEnum;
  createdTs?: string;
}

function CryoboxSummary({
  serialNo,
  sampleType,
  createdTs,
}: CryoboxSummaryProps) {
  return (
    <Panel mb={4}>
      <Grid container spacing={2}>
        {sampleType && (
          <Grid item xs="auto">
            <Chip label={sampleType} color="primary" variant="outlined" />
          </Grid>
        )}
        <Grid item xs>
          {serialNo && (
            <Typography component="h2" variant="h5">
              <strong>{serialNo}</strong>
            </Typography>
          )}
          {createdTs && (
            <Typography component="h2" variant="body1">
              Created: <Formatter input={createdTs} format="friendlyDateTime" />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Panel>
  );
}

export default CryoboxSummary;
