export const checkConnectionQuery = `
query checkConnectionQuery {
  checkConnection
}`;

export const findSampleBatchesQuery = `
query findSampleBatches($searchTerm: String!) {
  findSampleBatches(searchTerm: $searchTerm) {
    batchId
    sampleType
    createdTs
  },
}
`;

export const findCryoboxesQuery = `
query findCryoboxesQuery($freezerId: String, $bioBanked: Boolean!, $searchTerm: String) {
  findCryoboxes(freezerId: $freezerId, bioBanked: $bioBanked, searchTerm: $searchTerm) {
    aliquotPositions
    bioBanked
    createdBy
    createdTs
    freezerShelfNo
    height
    lastUpdatedBy
    rackPositionNo
    sampleType
    serialNo
    shelfRackNo
    updatedTs
    width
    freezer {
      freezerId
      name
      serialNo
      temp
    }
  },
}
`;

export const getSampleBatchQuery = `
query getSampleBatch($batchID: String!) {
  getSampleBatch(batchID: $batchID) {
    batchId
    createdBy
    createdTs
    lastUpdatedBy
    sampleType
    samples {
      aliquots {
        barcode
        createdBy
        volume
        updatedTs
        status
        sampleBatchId
        sampleBarcode
        mismatch
        lastUpdatedBy
        createdTs
      }
      volume
      status
      barcode
    }
    updatedTs
  },
}
`;

export const getCryoboxQuery = `
query getCryobox($serialNo: String!) {
  getCryobox(serialNo: $serialNo) {
    aliquotPositions
    bioBanked
    createdBy
    createdTs
    freezer {
      freezerId
      name
      serialNo
      temp
    }
    freezerShelfNo
    height
    lastUpdatedBy
    rackPositionNo
    sampleType
    serialNo
    shelfRackNo
    updatedTs
    width
  },
}
`;

export const listFreezersQuery = `
query listFreezers {
  listFreezers {
    freezerId
    name
    serialNo
    temp
  }
}
`;
