import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from "../../core/api/lab.api";
import {
  GetCryoboxResponse,
  UpdateCryoBoxInput,
  UpdateCryoboxResponse,
} from "../../core/api/lab.types";

/* Get Sample Batch */
export const getCryoboxThunk = createAsyncThunk(
  "cryobox/getCryobox",
  async (serialNo: string, { rejectWithValue }: any) => {
    const response: GetCryoboxResponse = await labApi.getCryobox(serialNo);
    if (response.data?.getCryobox) {
      return response.data?.getCryobox;
    }
    return rejectWithValue(response.errors);
  }
);

export const updateCryoboxThunk = createAsyncThunk(
  "cryobox/updateCryobox",
  async (values: UpdateCryoBoxInput, { rejectWithValue }: any) => {
    const response: UpdateCryoboxResponse = await labApi.updateCryobox(values);
    if (response.data?.updateCryobox) {
      return response.data?.updateCryobox;
    }
    return rejectWithValue(response.errors);
  }
);
