import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MainLayout from "../../../../layout/Main";
import ROUTES from "../../../../core/constants/routes.constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setValues } from "../../../../store/batchList";
import { setValues as setCryoboxesValues } from "../../../../store/cryoboxList";
import { responseSelector as freezerResponseSelector } from "../../../../store/freezerList";
import BatchSearch from "./components/BatchSearch";
import StorageSearch from "./components/StorageSearch";
import HomeOptions from "./components/HomeOptions";
import { BatchListFormValues } from "../../../../store/batchList/types";
import { listFreezersThunk } from "../../../../store/freezerList";

function HomeScene() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const freezerList = useAppSelector(freezerResponseSelector);

  useEffect(() => {
    if (!freezerList) {
      dispatch(listFreezersThunk());
    }
  }, [dispatch, freezerList]);

  const handleBatchSearch = (values: BatchListFormValues) => {
    dispatch(setValues(values));
    history.push(ROUTES.batches);
  };

  const handleStorageSearch = (values: any) => {
    dispatch(setCryoboxesValues(values));
    history.push(ROUTES.cryobox);
  };

  return (
    <MainLayout>
      <Box mb={6}>
        <Typography variant="h3" component="h1" align="center" paragraph>
          Laboratory Portal
        </Typography>
      </Box>
      <HomeOptions />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <BatchSearch onSearch={handleBatchSearch} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <StorageSearch
            onSearch={handleStorageSearch}
            freezerList={freezerList || []}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default HomeScene;
