import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from "../../core/api/lab.api";
import {
  CreateCryoboxResponse,
  CreateCryoBoxInput,
} from "../../core/api/lab.types";

/* Create new CryoBox */
export const createCryoboxThunk = createAsyncThunk(
  "storage/createCryobox",
  async (options: CreateCryoBoxInput, { rejectWithValue }: any) => {
    const response: CreateCryoboxResponse = await labApi.createCryoBox(options);
    if (response.data?.createCryobox) {
      return response.data?.createCryobox;
    }
    return rejectWithValue(response.errors);
  }
);
