import React, { ReactNode } from "react";
import Box from "@material-ui/core/Box";

interface AliquotStatusListProps {
  children: ReactNode;
}

const AliquotStatusList = ({ children }: AliquotStatusListProps) => {
  return (
    <Box>
      <table width={"100%"} style={{ margin: "0 auto" }}>
        <thead>
          <tr>
            <th align="center" style={{ width: 30 }}></th>
            <th align="left" style={{ width: 115 }}>
              Aliquot 1
            </th>
            <th align="left">Status 1</th>
            <th align="left" style={{ width: 70 }}>
              Vol 1 (ml)
            </th>
            <th align="left" style={{ width: 115 }}>
              Aliquot 2
            </th>
            <th align="left">Status 2</th>
            <th align="left" style={{ width: 70 }}>
              Vol 2 (ml)
            </th>
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </Box>
  );
};

export default AliquotStatusList;
