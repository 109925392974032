import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";
import { CryoboxCreateFormValues } from "./types";
import { SampleTypeCodeEnum, Cryobox } from "../../core/api/lab.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.cryoboxCreate.errors && state.cryoboxCreate.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.cryoboxCreate.status === StoreStatus.BUSY;

export const sampleTypeSelector = (
  state: RootState
): SampleTypeCodeEnum | null => state.cryoboxCreate.values?.sampleType || null;

export const valuesSelector = (
  state: RootState
): CryoboxCreateFormValues | null => state.cryoboxCreate.values || null;

export const responseSelector = (state: RootState): Cryobox | null =>
  state.cryoboxCreate.response || null;
