import { createAsyncThunk } from "@reduxjs/toolkit";
import labApi from "../../core/api/lab.api";
import {
  CreateSampleBatchResponse,
  CreateSampleBatchInput,
} from "../../core/api/lab.types";

/* Create New Sample Batch */
export const createSampleBatchThunk = createAsyncThunk(
  "batchCreate/createSampleBatch",
  async (inputs: CreateSampleBatchInput, { rejectWithValue }: any) => {
    const response: CreateSampleBatchResponse = await labApi.createSampleBatch(
      inputs
    );
    if (response.data?.createSampleBatch) {
      return response.data?.createSampleBatch;
    }
    return rejectWithValue(response.errors);
  }
);
