import React, { useEffect, useRef } from "react";
import { RiDeleteBinLine, RiLock2Line } from "react-icons/ri";
import { useFormikContext } from "formik";
import clsx from "classnames";
import IconButton from "@material-ui/core/IconButton";
import {
  FormInput,
  FormSelect,
  SelectOption,
  theme,
} from "@omnigenbiodata/react";
import { RiBarcodeLine } from "react-icons/ri";
import {
  PRIMARY_STATUSES,
  StatusOptions,
} from "../../../../../../../../../../core/constants/lab.constants";
import { SampleFormValues } from "../../../../../../../../../../store/batchCreate/types";
import { SampleTypeCodeEnum } from "../../../../../../../../../../core/api/lab.types";
import { useStyles } from "./component.styles";

interface BarcodeRowProps {
  index: number;
  isAdding: boolean;
  sampleType: SampleTypeCodeEnum;
  onDelete: (values: any) => void;
}

const BarcodeRow = ({
  index,
  isAdding = true,
  sampleType,
  onDelete,
  ...others
}: BarcodeRowProps) => {
  const formik = useFormikContext<{
    samples: SampleFormValues[];
  }>();
  const classes = useStyles();

  const myRef = useRef<null | HTMLTableRowElement>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [myRef]);

  return (
    <tr
      ref={index === formik.values.samples.length - 1 ? myRef : undefined}
      className={
        index === formik.values.samples.length - 1 && isAdding
          ? classes.newCell
          : undefined
      }
    >
      <td
        align="center"
        className={clsx({
          [classes.leftCellRed]: sampleType === SampleTypeCodeEnum.SERUM,
          [classes.leftCellPurple]: sampleType !== SampleTypeCodeEnum.SERUM,
        })}
      >
        {index + 1}
      </td>
      <td>
        <FormInput
          label="Barcode"
          name={`samples.${index}.barcode`}
          error={
            formik.errors?.samples
              ? (formik.errors?.samples[index] as string)
              : undefined
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.samples[index].barcode}
          disabled
          startAdornment={
            <RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />
          }
        />
      </td>
      <td>
        <FormInput
          label="Volume"
          type="number"
          name={`samples.${index}.volume`}
          min={0}
          step={0.1}
          error={
            formik.errors?.samples &&
            (formik.errors?.samples[index] as SampleFormValues)
              ? (formik.errors?.samples[index] as SampleFormValues).volume
              : undefined
          }
          touched={
            formik.touched?.samples && formik.touched?.samples[index]
              ? (formik.touched?.samples[index]).volume
              : false
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.samples[index].volume}
          endAdornment="ml"
        />
      </td>
      <td>
        <FormSelect
          label="Condition"
          name={`samples.${index}.condition`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Condition"
          options={
            Object.keys(PRIMARY_STATUSES).reduce(
              (previousValue: any[], key: keyof StatusOptions) => {
                return [
                  ...previousValue,
                  {
                    value: key,
                    label: PRIMARY_STATUSES[key],
                  },
                ];
              },
              []
            ) as SelectOption[]
          }
          value={formik.values.samples[index].condition}
        />
      </td>
      <td align="center">
        {index === formik.values.samples.length - 1 && (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => {
                onDelete(formik.values.samples[index]);
              }}
            >
              <RiDeleteBinLine fontSize={25} color={theme.palette.error.dark} />
            </IconButton>
          </>
        )}
        {index < formik.values.samples.length - 1 && (
          <RiLock2Line fontSize={25} color={theme.palette.grey[300]} />
        )}
      </td>
    </tr>
  );
};

export default BarcodeRow;
