import React from "react";
import { RiLock2Line, RiArrowLeftLine, RiPauseLine } from "react-icons/ri";
import { FaUndo } from "react-icons/fa";

import IconButton from "@material-ui/core/IconButton";
import { theme } from "@omnigenbiodata/react";

interface EndAdornmentProps {
  errors?: any;
  touched: boolean;
  value?: string;
  isActive?: string | boolean;
  index: number;
  columnIndex: number;
  onDelete: (index: number, col: number) => void;
}

const EndAdornment = ({
  errors,
  touched = false,
  value = "",
  isActive = false,
  index,
  columnIndex,
  onDelete = (index: number, col: number) => undefined,
}: EndAdornmentProps) => {
  return (
    <>
      {isActive ? (
        <RiArrowLeftLine fontSize={25} color={theme.palette.primary.main} />
      ) : errors && touched ? (
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            onDelete(index, columnIndex);
          }}
        >
          <FaUndo fontSize={20} color={theme.palette.error.dark} />
        </IconButton>
      ) : !errors && value ? (
        <RiLock2Line fontSize={25} color={theme.palette.grey[300]} />
      ) : (
        <RiPauseLine fontSize={25} color={theme.palette.grey[500]} />
      )}
    </>
  );
};

export default EndAdornment;
